"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListMetadataQuery = exports.ListHashtagMetadataQuery = exports.ListForYouMetadataQuery = exports.ListProfileMetadataQuery = exports.ListNativeMetadataQuery = exports.ListSearchMetadataQuery = exports.ListVideoMetadataQuery = void 0;
var common_1 = require("@shared/models/common");
var t = __importStar(require("io-ts"));
var NumberFromString_1 = require("io-ts-types/NumberFromString");
var Nature = __importStar(require("../../Nature"));
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.VideoType`
 */
exports.ListVideoMetadataQuery = t.type({
    nature: Nature.VideoType,
}, 'ListVideoMetadataQuery');
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.SearchType`
 */
exports.ListSearchMetadataQuery = t.type({
    nature: Nature.SearchType,
    query: t.union([t.string, t.undefined]),
}, 'ListSearchMetadataQuery');
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.NativeType`
 */
exports.ListNativeMetadataQuery = t.type({
    nature: Nature.NativeType,
    description: t.union([t.string, t.undefined]),
}, 'ListNativeMetadataQuery');
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.ProfileType`
 */
exports.ListProfileMetadataQuery = t.type({
    nature: Nature.ProfileType,
}, 'ListProfileMetadataQuery');
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.ForYouType`
 */
exports.ListForYouMetadataQuery = t.type({
    nature: Nature.ForYouType,
    description: t.union([t.string, t.undefined]),
}, 'ListProfileMetadataQuery');
/**
 * The codec for the Query `filter` for GET /v2/metadata endpoint
 * when filter[nature] = `Nature.HashtagType`
 */
exports.ListHashtagMetadataQuery = t.type({
    nature: Nature.HashtagType,
    tag: t.union([t.string, t.undefined]),
}, 'ListHashtagMetadataQuery');
/**
 * The codec for the Query used for GET /v2/metadata endpoint
 */
exports.ListMetadataQuery = t.partial({
    publicKey: t.union([t.string, t.undefined]),
    experimentId: t.union([t.string, t.undefined]),
    researchTag: t.union([t.string, t.undefined]),
    amount: t.union([NumberFromString_1.NumberFromString, t.number, t.undefined]),
    skip: t.union([NumberFromString_1.NumberFromString, t.number, t.undefined]),
    format: t.union([common_1.Format, t.undefined]),
    // we want the filter to be specific for
    // the nature given
    filter: t.union([
        t.union([
            exports.ListVideoMetadataQuery,
            exports.ListSearchMetadataQuery,
            exports.ListNativeMetadataQuery,
            exports.ListProfileMetadataQuery,
            exports.ListForYouMetadataQuery,
            exports.ListHashtagMetadataQuery,
        ]),
        t.undefined,
    ]),
}, 'ListMetadataQuery');
