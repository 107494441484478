import { createTheme, lighten, darken, ThemeProvider, useTheme, alpha, makeStyles as _makeStyles, } from '@material-ui/core/styles';
var pink = '#E33180';
var lightPink = lighten(pink, 0.2);
var darkPink = darken(pink, 0.2);
var grey = '#5B5F6F';
var lightGrey = alpha(grey, 0.2);
var grey100 = "#DDE3EE";
var darkGrey = darken(grey, 0.2);
var yellow = '#DA9D00';
var violet = '#572B8F';
var lightViolet = lighten(violet, 0.2);
var darkViolet = darken(violet, 0.2);
var white = '#F5F5F5';
var black = '#1A030E';
export var YCAITheme = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: 'Trex',
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 800,
        h1: {
            fontWeight: 800,
            fontSize: '4rem',
            marginBottom: 32,
        },
        h2: {
            fontWeight: 800,
            fontSize: '3.6rem',
            marginBottom: 16,
        },
        h3: {
            fontWeight: 800,
            fontSize: '1.8rem',
            marginBottom: 10,
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.6rem',
            marginBottom: 8,
        },
        h5: {
            fontWeight: 800,
            fontSize: '1.3rem',
        },
        h6: {
            fontWeight: 400,
            fontSize: '1.1rem',
            marginBottom: 4,
        },
        subtitle1: {
            fontSize: '1rem',
            fontWeight: 300,
            lineHeight: 1.4,
            marginBottom: 20,
        },
        subtitle2: {
            fontSize: '0.9rem',
            fontWeight: 800,
            lineHeight: 1.1,
        },
        body1: {
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.1,
            color: black,
        },
        body2: {
            fontWeight: 400,
            fontSize: '0.8rem',
            lineHeight: 1.2,
        },
        caption: {
            fontSize: '0.7rem',
            lineHeight: 1.3,
        },
    },
    overrides: {
        MuiTabs: {
            root: {
                background: pink,
            },
        },
        MuiButton: {
            sizeSmall: {
                fontSize: '0.7rem',
            },
            contained: {
                color: grey,
                backgroundColor: lightGrey,
            },
            containedPrimary: {
                color: white,
            },
            containedSecondary: {
                color: white,
                background: grey,
            },
        },
        MuiTypography: {
            root: {
                whiteSpace: 'pre-wrap',
            },
        },
    },
    palette: {
        text: {
            primary: grey,
            secondary: black,
        },
        common: {
            white: white,
            black: black,
        },
        background: {
            default: white,
        },
        primary: {
            light: lightPink,
            main: pink,
            dark: darkPink,
            contrastText: white,
        },
        secondary: {
            light: lightViolet,
            main: violet,
            dark: darkViolet,
            contrastText: white,
        },
        violet: {
            light: violet,
            main: violet,
            dark: darkViolet,
            contrastText: white,
        },
        yellow: {
            light: yellow,
            main: yellow,
        },
        grey: {
            100: grey100,
            300: lightGrey,
            500: grey,
            800: darkGrey,
        },
    },
});
export var makeStyles = function (styles, options) {
    return _makeStyles(styles, options);
};
export { ThemeProvider, useTheme };
