var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
var AnalyticsIcon = function (_a) {
    var color = _a.color, props = __rest(_a, ["color"]);
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M22 6.92L20.59 5.51L17.74 8.72C15.68 6.4 12.83 5 9.61 5C6.72 5 4.07 6.16 2 8L3.42 9.42C5.12 7.93 7.27 7 9.61 7C12.35 7 14.7 8.26 16.38 10.24L13.5 13.48L9.5 9.48L2 16.99L3.5 18.49L9.5 12.48L13.5 16.48L17.55 11.93C18.3 13.28 18.8 14.83 18.99 16.48H21C20.78 14.18 20.05 12.09 18.96 10.34L22 6.92Z", fill: color })));
};
export default AnalyticsIcon;
