var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as endpoints from '@yttrex/shared/endpoints';
import * as tkEndpoints from '@tktrex/shared/endpoints';
import { MakeAPIClient } from '@shared/providers/api.provider';
import { available, queryStrict } from 'avenger';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
export var GetTabouleQueries = function (_a) {
    var baseURL = _a.baseURL, accessToken = _a.accessToken;
    var YTAPI = MakeAPIClient({
        baseURL: baseURL,
        getAuth: function (req) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, req];
        }); }); },
        onUnauthorized: function (res) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, res];
        }); }); },
    }, endpoints).API;
    var TK_API = MakeAPIClient({
        baseURL: baseURL,
        getAuth: function (req) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, req];
        }); }); },
        onUnauthorized: function (res) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, res];
        }); }); },
    }, tkEndpoints).API;
    var ccRelatedUsers = queryStrict(function (input) {
        return pipe(YTAPI.v3.Creator.CreatorRelatedChannels(__assign(__assign({}, input), { Headers: {
                'x-authorization': accessToken !== null && accessToken !== void 0 ? accessToken : '',
            } })), TE.map(function (_a) {
            var totalRecommendations = _a.totalRecommendations, r = __rest(_a, ["totalRecommendations"]);
            return (__assign(__assign({}, r), { total: totalRecommendations }));
        }));
    }, available);
    var getExperimentById = queryStrict(function (input) {
        return pipe(YTAPI.v2.Public.GetExperimentById(input), TE.map(function (content) { return ({
            total: content.steps.length,
            content: content.steps,
        }); }));
    }, available);
    var getExperimentList = queryStrict(function (input) {
        return pipe(YTAPI.v2.Public.GetExperimentList(input), TE.map(function (content) {
            return {
                total: content.total,
                content: content.content.map(function (c) { return (__assign(__assign({}, c), { id: c.experimentId })); }),
            };
        }));
    }, available);
    var personalHomes = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalStatsByPublicKey(input), TE.map(function (content) { return ({
            total: content.stats.home,
            content: content.homes,
        }); }));
    }, available);
    var personalAds = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalStatsByPublicKey(input), TE.map(function (content) { return ({
            total: content.ads.length,
            content: content.ads,
        }); }));
    }, available);
    var personalVideos = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalStatsByPublicKey(input), TE.map(function (content) { return ({
            total: content.stats.video,
            content: content.videos,
        }); }));
    }, available);
    var personalSearches = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalStatsByPublicKey(input), TE.map(function (content) { return ({
            total: content.stats.search,
            content: content.searches,
        }); }));
    }, available);
    var tikTokPersonalHTMLSummary = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalSummaryByPublicKey(input), TE.map(function (content) { return ({
            total: content.htmls.length,
            content: content.htmls,
        }); }));
    }, available);
    var tikTokPersonalSearch = queryStrict(function (input) {
        return pipe(YTAPI.v1.Public.GetPersonalSearchByPublicKey(input), TE.map(function (content) { return ({
            total: content.metadata.length,
            content: content.metadata,
        }); }));
    }, available);
    var tikTokSearches = queryStrict(function (input) {
        return pipe(TK_API.v2.Public.GetSearchByQuery(input), TE.map(function (content) { return ({
            total: content.length,
            content: content,
        }); }));
    }, available);
    return {
        ccRelatedUsers: ccRelatedUsers,
        getExperimentById: getExperimentById,
        getExperimentList: getExperimentList,
        personalHomes: personalHomes,
        personalAds: personalAds,
        personalVideos: personalVideos,
        personalSearches: personalSearches,
        tikTokPersonalHTMLSummary: tikTokPersonalHTMLSummary,
        tikTokPersonalSearch: tikTokPersonalSearch,
        tikTokSearches: tikTokSearches,
    };
};
