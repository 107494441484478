import { Card, CardContent, Divider, Link } from '@material-ui/core';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { getYTEmbeddingURLById, getYTVideoURLById, } from '@shared/utils/yt.utils';
import * as QR from 'avenger/lib/QueryResult';
import { WithQueries } from 'avenger/lib/react';
import React from 'react';
import { oneCreatorVideo } from '../../state/dashboard/creator.queries';
import { LazyFullSizeLoader } from './FullSizeLoader';
export var YTVideo = function (_a) {
    var videoId = _a.videoId;
    return (React.createElement(WithQueries, { queries: { oneCreatorVideo: oneCreatorVideo }, params: { oneCreatorVideo: { params: { videoId: videoId } } }, render: QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
            var video = _a.oneCreatorVideo;
            return (React.createElement(Card, { style: { boxShadow: 'none', backgroundColor: 'transparent' } },
                React.createElement("iframe", { sandbox: "allow-scripts allow-same-origin", src: getYTEmbeddingURLById(videoId), width: "100%", height: 315, style: {
                        border: 'none',
                    } }),
                React.createElement(CardContent, { style: {
                        paddingLeft: '0px',
                        paddingTop: '10px',
                        paddingBottom: '0px',
                    } },
                    React.createElement(Link, { color: "textSecondary", variant: "h5", href: getYTVideoURLById(videoId), rel: "noreferrer", target: "_blank", underline: "none" }, video.title),
                    React.createElement(Divider, { light: true, style: { marginTop: '24px' } }))));
        }) }));
};
