import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GemCard from './GemCard';
var RecommendationList = function (_a) {
    var recommendations = _a.recommendations, onDeleteClick = _a.onDeleteClick;
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { mb: 2 },
            React.createElement(Typography, null,
                t('recommendations:total'),
                " ",
                recommendations.length)),
        React.createElement(Grid, { container: true, spacing: 2, style: { paddingRight: '200px' } }, recommendations.map(function (r) { return (React.createElement(Grid, { item: true, xs: 10, sm: 10, md: 10, lg: 6, key: r.urlId },
            React.createElement(GemCard, { key: r.urlId, data: r, onDeleteClick: onDeleteClick }))); }))));
};
export default RecommendationList;
