"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var common_1 = require("./common");
var Auth_1 = require("./Auth");
var ContentCreator_1 = require("./ContentCreator");
var ChannelADV_1 = require("./stats/ChannelADV");
var CreatorStats_1 = require("./CreatorStats");
var HandshakeBody_1 = require("./HandshakeBody");
var Recommendation_1 = require("./Recommendation");
var ChannelRelated_1 = require("./ChannelRelated");
var Video_1 = require("./Video");
var ContributionEvent_1 = require("./ContributionEvent");
var Experiment_1 = require("./Experiment");
var Step = __importStar(require("./Step"));
var Supporter_1 = require("./Supporter");
exports["default"] = __assign({ 
    // common
    StringOrNull: common_1.StringOrNull, What: common_1.What, Format: common_1.Format, 
    // handshake
    HandshakeBody: HandshakeBody_1.HandshakeBody, HandshakeResponse: HandshakeBody_1.HandshakeResponse, ContributionEvent: ContributionEvent_1.ContributionEvent, VideoContributionEvent: ContributionEvent_1.VideoContributionEvent, AddEventsBody: ContributionEvent_1.AddEventsBody, 
    // supporter
    Supporter: Supporter_1.Supporter, 
    // guardoni
    // guardoni experiments (to be moved)
    GuardoniExperiment: Experiment_1.GuardoniExperiment, GetDirectiveOutput: Experiment_1.GetDirectiveOutput, GetExperimentListOutput: Experiment_1.GetExperimentListOutput, GetPublicDirectivesOutput: Experiment_1.GetPublicDirectivesOutput, CreateExperimentResponse: Experiment_1.CreateExperimentResponse, CreateExperimentSuccessResponse: Experiment_1.CreateExperimentSuccessResponse, 
    // ycai
    // content creator
    RegisterContentCreatorBody: ContentCreator_1.RegisterContentCreatorBody, AuthResponse: Auth_1.AuthResponse, AuthorizedContentCreator: ContentCreator_1.AuthorizedContentCreator, ContentCreator: ContentCreator_1.ContentCreator, 
    // cc recommendations
    CreateRecommendation: Recommendation_1.CreateRecommendation, Recommendation: Recommendation_1.Recommendation, PartialRecommendation: Recommendation_1.PartialRecommendation, RecommendationList: Recommendation_1.RecommendationList, 
    // cc videos
    Video: Video_1.Video, UpdateVideoBody: Video_1.UpdateVideoBody, ContentCreatorVideosOutput: ContentCreator_1.ContentCreatorVideosOutput, 
    // cc stats
    ChannelADV: ChannelADV_1.ChannelADV, ChannelADVStats: ChannelADV_1.ChannelADVStats, CreatorStats: CreatorStats_1.CreatorStats, CreatorStatContent: CreatorStats_1.CreatorStatContent, ChannelRelated: ChannelRelated_1.ChannelRelated, GetRelatedChannelsOutput: ChannelRelated_1.GetRelatedChannelsOutput }, Step);
