"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.toAppError = exports.AppError = void 0;
var ts_io_error_1 = require("ts-io-error");
var APIError_1 = require("./APIError");
var AppError = /** @class */ (function (_super) {
    __extends(AppError, _super);
    function AppError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'AppError';
        return _this;
    }
    return AppError;
}(ts_io_error_1.IOError));
exports.AppError = AppError;
var toAppError = function (e) {
    var _a, _b;
    if ((0, APIError_1.isAPIError)(e)) {
        return __assign(__assign({}, e), { name: 'AppError' });
    }
    if (e instanceof Error) {
        return {
            name: 'AppError',
            message: e.message,
            status: 500,
            details: { kind: 'ClientError', meta: [], status: 'client error' }
        };
    }
    return {
        name: 'AppError',
        message: (_a = e.name) !== null && _a !== void 0 ? _a : "Unknown Error",
        status: 500,
        details: {
            kind: 'ClientError',
            meta: [],
            status: (_b = e.message) !== null && _b !== void 0 ? _b : 'Something bad happened'
        }
    };
};
exports.toAppError = toAppError;
