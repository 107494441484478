import { Box } from '@material-ui/core';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import * as React from 'react';
export var ErrorOverlay = function (error) {
    return (React.createElement(Box, { display: 'flex', alignContent: 'center', width: '100%', alignSelf: 'center', alignItems: 'center', style: {
            height: '100%',
        } },
        React.createElement(Box, { style: {
                margin: 'auto',
                width: '100%',
            } }, ErrorBox(error))));
};
