var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, FormControl, FormGroup, FormHelperText, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles, Typography } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as E from 'fp-ts/lib/Either';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { assignAccessToken, deleteProfile } from '../../../state/dashboard/creator.commands';
import { downloadTXTFile } from '../../../state/dashboard/public.commands';
import { doUpdateCurrentView } from '../../../utils/location.utils';
import UnlinkProfileButton from '../../common/UnlinkProfileButton';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginBottom: theme.spacing(10),
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
}); });
export var AccessTokenBox = function (_a) {
    var _b;
    var profile = _a.profile;
    var t = useTranslation().t;
    var _c = __read(React.useState((_b = profile === null || profile === void 0 ? void 0 : profile.accessToken) !== null && _b !== void 0 ? _b : ''), 2), token = _c[0], setToken = _c[1];
    var _d = __read(React.useState(token === ''), 2), authTokenVisible = _d[0], setAuthTokenVisible = _d[1];
    var _e = __read(React.useState(null), 2), error = _e[0], setError = _e[1];
    var classes = useStyles();
    var tokenValue = token === '' ? '' : authTokenVisible ? token : 'ACTK********';
    return (React.createElement(Box, { className: classes.root },
        React.createElement(Typography, { variant: "h4", component: "h2", color: "textSecondary" }, t('settings:access_token_title')),
        React.createElement(Typography, { color: "textPrimary", gutterBottom: true }, t('settings:access_token_description')),
        React.createElement(FormGroup, { row: true },
            React.createElement(Grid, { container: true, className: classes.formControl, spacing: 2, justifyContent: "flex-end", alignItems: "flex-end" },
                React.createElement(Grid, { item: true, xs: 9 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { htmlFor: "access-token" }, t('settings:access_token')),
                        React.createElement(Input, { fullWidth: true, value: tokenValue, readOnly: !authTokenVisible, onChange: function (e) {
                                setToken(e.target.value);
                            }, endAdornment: (profile === null || profile === void 0 ? void 0 : profile.accessToken) !== undefined ? (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            setAuthTokenVisible(!authTokenVisible);
                                            return [2 /*return*/];
                                        });
                                    }); }, edge: "end" }, authTokenVisible ? (React.createElement(VisibilityOffIcon, null)) : (React.createElement(VisibilityIcon, null))))) : null }),
                        error !== null ? (React.createElement(FormHelperText, { error: true }, error.message)) : null)),
                React.createElement(Grid, { item: true, xs: 3, style: { textAlign: 'right' } }, (profile === null || profile === void 0 ? void 0 : profile.accessToken) !== undefined ? (React.createElement(Button, { color: "secondary", variant: "outlined", size: "small", startIcon: React.createElement(CloudDownload, null), onClick: function () {
                        void downloadTXTFile({
                            name: 'access-token.txt',
                            data: profile.accessToken,
                        })();
                    } }, t('actions:download'))) : null)),
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(Button, { variant: "outlined", color: "primary", size: "small", onClick: function () {
                            setError(null);
                            if (authTokenVisible) {
                                void assignAccessToken({ token: token })().then(function (c) {
                                    if (E.isLeft(c)) {
                                        setError(c.left);
                                    }
                                    else if (E.isRight(c)) {
                                        if (c.right !== null) {
                                            setAuthTokenVisible(!authTokenVisible);
                                        }
                                    }
                                });
                            }
                            else {
                                setAuthTokenVisible(true);
                            }
                        } }, authTokenVisible
                        ? t('actions:save_access_token')
                        : t('actions:edit_access_token'))),
                React.createElement(Grid, { item: true, xs: 3 }, (profile === null || profile === void 0 ? void 0 : profile.accessToken) !== undefined ? (React.createElement(UnlinkProfileButton, { variant: "outlined", color: "secondary", size: "small", onLogout: function () {
                        void deleteProfile({})()
                            .then(doUpdateCurrentView({ view: 'index' }))
                            .then(function () {
                            setToken('');
                            setAuthTokenVisible(true);
                            setError(null);
                        });
                    } })) : null)))));
};
