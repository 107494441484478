"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForYouMetadata = void 0;
var t = __importStar(require("io-ts"));
var Nature_1 = require("../Nature");
var Author_1 = require("./Author");
var MetadataBase_1 = require("./MetadataBase");
var Metrics_1 = require("./Metrics");
var Music_1 = require("./Music");
exports.ForYouMetadata = t.intersection([
    MetadataBase_1.TKMetadataBase,
    Nature_1.ForYouN,
    t.type({ nature: Nature_1.ForYouN }),
    t.type({
        // baretext is the smallest part of the description,
        // not including the tags
        baretext: t.union([t.string, t.undefined]),
        // description is the whole text written below the video,
        // including the tags
        description: t.union([t.string, t.undefined]),
        author: t.union([Author_1.Author, t.undefined]),
        music: t.union([Music_1.Music, t.undefined]),
        // the hashtags, with their leading #
        // note: they do not seem to be cleaned at the moment,
        // some have trailing whitespace
        hashtags: t.array(t.string),
        metrics: Metrics_1.Metrics,
    }, 'foryou'),
], 'ForYouMetadata');
