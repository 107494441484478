"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ListMetadataQuery = exports.ListHomeMetadataQuery = exports.ListSearchMetadataQuery = exports.ListVideoMetadataQuery = void 0;
var common_1 = require("@shared/models/common");
var t = __importStar(require("io-ts"));
var BooleanFromString_1 = require("io-ts-types/lib/BooleanFromString");
var NumberFromString_1 = require("io-ts-types/lib/NumberFromString");
var Nature = __importStar(require("../../../Nature"));
exports.ListVideoMetadataQuery = t.type({
    nature: Nature.VideoNatureType,
    title: t.union([t.string, t.undefined]),
    authorName: t.union([t.string, t.undefined])
}, 'ListVideoMetadataQuery');
exports.ListSearchMetadataQuery = t.type({
    nature: Nature.SearchNatureType,
    query: t.union([t.string, t.undefined])
}, 'ListSearchMetadataQuery');
exports.ListHomeMetadataQuery = t.type({
    nature: Nature.HomeNatureType,
    login: t.union([BooleanFromString_1.BooleanFromString, t.undefined], 'login?')
}, 'ListSearchMetadataQuery');
exports.ListMetadataQuery = t.partial({
    publicKey: t.union([t.string, t.undefined], 'publicKey?'),
    experimentId: t.union([t.string, t.undefined], 'experimentId?'),
    researchTag: t.union([t.string, t.undefined], 'researchTag?'),
    amount: t.union([NumberFromString_1.NumberFromString, t.number, t.undefined], 'amount?'),
    skip: t.union([NumberFromString_1.NumberFromString, t.number, t.undefined], 'skip?'),
    format: t.union([common_1.Format, t.undefined], 'format?'),
    filter: t.union([
        exports.ListVideoMetadataQuery,
        exports.ListSearchMetadataQuery,
        exports.ListHomeMetadataQuery,
        t.undefined,
    ], 'queryFilter?')
}, 'ListMetadataQuery');
