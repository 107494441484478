"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nature = exports.HashtagsN = exports.ProfileN = exports.NativeVideoN = exports.SearchN = exports.VideoN = exports.FollowingN = exports.ForYouN = exports.NatureType = exports.HashtagType = exports.ProfileType = exports.NativeType = exports.SearchType = exports.VideoType = exports.CreatorType = exports.FollowingType = exports.ForYouType = void 0;
var t = __importStar(require("io-ts"));
exports.ForYouType = t.literal('foryou');
exports.FollowingType = t.literal('following');
exports.CreatorType = t.literal('creator');
exports.VideoType = t.literal('video');
exports.SearchType = t.literal('search');
exports.NativeType = t.literal('native');
exports.ProfileType = t.literal('profile');
exports.HashtagType = t.literal('tag');
exports.NatureType = t.union([
    exports.ForYouType,
    exports.FollowingType,
    exports.CreatorType,
    exports.VideoType,
    exports.SearchType,
    exports.NativeType,
    exports.ProfileType,
    exports.HashtagType,
], 'NatureType');
exports.ForYouN = t.strict({
    type: exports.ForYouType,
}, 'ForYouN');
exports.FollowingN = t.type({
    type: exports.FollowingType,
}, 'FollowingN');
exports.VideoN = t.type({
    type: exports.VideoType,
    videoId: t.string,
    authorId: t.string,
}, 'VideoN');
exports.SearchN = t.strict({
    type: exports.SearchType,
    query: t.union([t.string, t.null]),
}, 'SearchNature');
exports.NativeVideoN = t.strict({
    type: exports.NativeType,
    videoId: t.string,
    authorId: t.string,
}, 'VideoNature');
exports.ProfileN = t.type({
    type: t.union([exports.CreatorType, exports.ProfileType]),
    creatorName: t.string,
}, 'ProfileN');
exports.HashtagsN = t.type({
    type: exports.HashtagType,
    hashtag: t.string,
}, 'HashtagN');
exports.Nature = t.union([exports.ForYouN, exports.FollowingN, exports.VideoN, exports.SearchN, exports.ProfileN, exports.HashtagsN, exports.NativeVideoN], 'Nature');
