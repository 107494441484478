"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CreateExperimentResponse = exports.CreateExperimentSuccessResponse = exports.GetPublicDirectivesOutput = exports.GetExperimentListOutput = exports.GetDirectiveOutput = exports.GuardoniExperiment = void 0;
var t = __importStar(require("io-ts"));
var nonEmptyArray_1 = require("io-ts-types/lib/nonEmptyArray");
var DateFromISOString_1 = require("io-ts-types/lib/DateFromISOString");
var Step_1 = require("./Step");
exports.GuardoniExperiment = t.strict({
    experimentId: t.string,
    when: t.string,
    steps: t.array(Step_1.Step),
    publicKey: t.union([t.string, t.undefined]),
    status: t.union([t.literal('exist'), t.literal('active'), t.undefined])
}, 'GuardoniExperiment');
exports.GetDirectiveOutput = (0, nonEmptyArray_1.nonEmptyArray)(exports.GuardoniExperiment, 'GetDirectiveOutput');
exports.GetExperimentListOutput = t.strict({
    content: t.array(exports.GuardoniExperiment),
    total: t.number,
    pagination: t.any
}, 'GetExperimentListOutput');
exports.GetPublicDirectivesOutput = t.array(exports.GuardoniExperiment, 'GetPublicDirectivesOutput');
exports.CreateExperimentSuccessResponse = t.strict({
    status: t.union([t.literal('exist'), t.literal('created')]),
    experimentId: t.string,
    since: DateFromISOString_1.DateFromISOString,
    steps: t.array(Step_1.Step)
}, 'CreateExperimentSuccessResponse');
exports.CreateExperimentResponse = t.union([
    t.type({ error: t.type({ message: t.string }) }),
    exports.CreateExperimentSuccessResponse,
], 'CreateExperimentResponse');
