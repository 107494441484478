import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
export var FullSizeLoader = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'primary' : _b;
    return (React.createElement("div", { style: {
            height: '100%',
            width: '100%',
            textAlign: 'center',
            minHeight: 600,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        } },
        React.createElement(CircularProgress, { color: color })));
};
export var LazyFullSizeLoader = function () { return (React.createElement(FullSizeLoader, null)); };
