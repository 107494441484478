var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteGemConfirmDialog from '../dialogs/DeleteGemConfirmDialog';
var useStyles = makeStyles(function (theme) { return ({
    button: {
        fontWeight: 'bold',
        lineHeight: 1,
        marginRight: theme.spacing(2),
        minWidth: 0,
        padding: 0,
    },
    arrowButton: {
        '&:disabled': {
            color: theme.palette.grey[500],
        },
    },
}); });
var DeleteGemButton = function (_a) {
    var data = _a.data, _b = _a.variant, variant = _b === void 0 ? 'label' : _b, onDeleteClick = _a.onDeleteClick;
    var t = useTranslation().t;
    var classes = useStyles();
    var _c = __read(React.useState(false), 2), showConfirmDialog = _c[0], setShowConfirmDialog = _c[1];
    var handleDeleteConfirmation = function () {
        onDeleteClick(data);
        setShowConfirmDialog(false);
    };
    var button = variant === 'icon' ? (React.createElement(IconButton, { "aria-label": t('actions:move_recommendation_up'), color: "primary", className: classes.arrowButton, 
        // there seems to be an eslint bug,
        // there is no way to get rid of all the warnings whatever I do
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        onClick: function () { return setShowConfirmDialog(true); }, size: "small" },
        React.createElement(DeleteIcon, null))) : (React.createElement(Button, { className: classes.button, onClick: function () { return setShowConfirmDialog(true); }, size: "small", variant: "text" }, t('actions:delete_recommendation_button')));
    return (React.createElement(Box, null,
        button,
        React.createElement(DeleteGemConfirmDialog, { open: showConfirmDialog, onConfirm: handleDeleteConfirmation, onCancel: function () { return setShowConfirmDialog(false); } })));
};
export default DeleteGemButton;
