"use strict";
/**
 * Swagger provider
 *
 * Generate Swagger configuration from our endpoints definition
 *
 * Here is a the OpenAPI Specs used by swagger
 *
 * https://swagger.io/docs/specification/about/
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.generateDoc = void 0;
var function_1 = require("fp-ts/lib/function");
var R = __importStar(require("fp-ts/lib/Record"));
var S = __importStar(require("fp-ts/lib/string"));
var IOTSToOpenAPISchema_1 = require("./IOTSToOpenAPISchema");
var utils_1 = require("./utils");
var t = __importStar(require("io-ts"));
/**
 * Check the endpoint has body defined
 *
 */
var hasRequestBody = function (e) {
    var _a;
    return ((e.Method === 'POST' || e.Method === 'PUT' || e.Method === 'PATCH') &&
        ((_a = e.Input) === null || _a === void 0 ? void 0 : _a.Body) !== undefined);
};
var getInnerSchemaName = function (tt) {
    if (tt.startsWith('Array<')) {
        // console.log(tt);
        var innerName = tt.replace('Array<', '').replace('>', '');
        // console.log('inner name', innerName);
        return innerName;
    }
    return tt;
};
/**
 * generate the Open API schema from endpoint
 */
var apiSchemaFromEndpoint = function (key, e, tags, getDocumentation) {
    var _a;
    var _b, _c, _d, _e;
    var responseStatusCode = e.Method === 'POST' ? 201 : 200;
    var input = e.Input;
    var Headers = input.Headers;
    // derive headers from io-ts definition of e.Input.Headers
    var headerParameters = Headers !== undefined
        ? R.keys(Headers.props).reduce(function (acc, k) {
            return acc.concat({
                name: k,
                "in": 'header',
                required: false,
                schema: (0, IOTSToOpenAPISchema_1.getOpenAPISchema)((Headers === null || Headers === void 0 ? void 0 : Headers.props)[k])
            });
        }, [])
        : [];
    // add security when "x-authorization" header is defined
    var security = ((_b = Headers === null || Headers === void 0 ? void 0 : Headers.props) === null || _b === void 0 ? void 0 : _b['x-authorization']) !== undefined ? [{ ACTToken: [] }] : [];
    var Query = input.Query;
    // derive query parameters from io-ts definition of e.Input.Query
    var queryParameters = Query !== undefined
        ? R.keys(Query.props).reduce(function (acc, k) {
            return acc.concat({
                name: k,
                "in": 'query',
                required: (Query === null || Query === void 0 ? void 0 : Query._tag) === 'PartialType',
                schema: (0, IOTSToOpenAPISchema_1.getOpenAPISchema)((Query === null || Query === void 0 ? void 0 : Query.props)[k])
            });
        }, [])
        : [];
    utils_1.swaggerLogger.debug('endpoint query', (_c = e.Input) === null || _c === void 0 ? void 0 : _c.Params);
    var Params = input.Params;
    // derive path parameters from io-ts definition of e.Input.Params
    var pathParameters = Params !== undefined
        ? R.keys(Params.props).reduce(function (acc, k) {
            var _a = (0, IOTSToOpenAPISchema_1.getOpenAPISchema)((Params === null || Params === void 0 ? void 0 : Params.props)[k]), required = _a.required, schema = __rest(_a, ["required"]);
            return acc.concat({
                name: k,
                "in": 'path',
                required: true,
                schema: schema
            });
        }, [])
        : [];
    // combine all parameters
    var parameters = __spreadArray(__spreadArray(__spreadArray([], __read(headerParameters), false), __read(queryParameters), false), __read(pathParameters), false);
    //   console.log('parameters', parameters);
    // add definition of request body, if needed
    var requestBody = hasRequestBody(e)
        ? {
            requestBody: {
                content: {
                    'application/json': {
                        schema: {
                            $ref: "#/components/schemas/".concat(getInnerSchemaName(((_d = e.Input) === null || _d === void 0 ? void 0 : _d.Body).name))
                        }
                    }
                }
            }
        }
        : {};
    var schemaName = getInnerSchemaName(e.Output.name);
    // define success response
    var successResponse = (_a = {},
        _a[responseStatusCode] = {
            description: schemaName,
            content: {
                'application/json': {
                    schema: {
                        $ref: "#/components/schemas/".concat(schemaName)
                    }
                }
            }
        },
        _a);
    // TODO: define error response
    // eslint-disable-next-line
    // console.log(schemaName, { hasDocumentationMethod, description });
    return __assign(__assign({ summary: (_e = e.title) !== null && _e !== void 0 ? _e : key, description: getDocumentation(e), tags: tags, parameters: parameters, security: security }, requestBody), { responses: __assign({}, successResponse) });
};
var getPaths = function (endpoints, getDocumentation) {
    return (0, function_1.pipe)(endpoints, R.reduceWithIndex(S.Ord)({ paths: {}, schemas: {} }, function (versionKey, versionAcc, versionEndpoints) {
        return (0, function_1.pipe)(versionEndpoints, R.reduceWithIndex(S.Ord)(versionAcc, function (scopeKey, scopeAcc, scopeEndpoints) {
            return (0, function_1.pipe)(scopeEndpoints, R.reduceWithIndex(S.Ord)({ paths: {}, schemas: {} }, function (key, endpointAcc, endpoint) {
                var _a, _b, _c;
                var _d, _e, _f;
                // get swagger compatible path
                var endpointStaticPath = endpoint.getStaticPath(function (param) { return "{".concat(param, "}"); });
                var prevEndpoints = (_d = endpointAcc.paths[endpointStaticPath]) !== null && _d !== void 0 ? _d : undefined;
                var previousMethodSchema = prevEndpoints === null || prevEndpoints === void 0 ? void 0 : prevEndpoints[endpoint.Method.toLowerCase()];
                var currentEndpointSchema = apiSchemaFromEndpoint(key, endpoint, (_e = endpoint.tags) !== null && _e !== void 0 ? _e : [
                    'all',
                    // `${versionKey} - ${scopeKey}`
                ], getDocumentation);
                var currentSchema = ((_f = endpoint.Output) === null || _f === void 0 ? void 0 : _f.name)
                    ? (_a = {},
                        _a[getInnerSchemaName(endpoint.Output.name)] = (0, IOTSToOpenAPISchema_1.getOpenAPISchema)(endpoint.Output),
                        _a) : {};
                return {
                    schemas: __assign({}, currentSchema),
                    paths: __assign(__assign({}, endpointAcc.paths), (_b = {}, _b[endpointStaticPath] = __assign(__assign(__assign({}, prevEndpoints), previousMethodSchema), (_c = {}, _c[endpoint.Method.toLowerCase()] = currentEndpointSchema, _c)), _b))
                };
            }), function (endpointResult) { return ({
                schemas: __assign(__assign({}, scopeAcc.schemas), endpointResult.schemas),
                paths: __assign(__assign({}, scopeAcc.paths), endpointResult.paths)
            }); });
        }), function (defs) { return ({
            paths: __assign(__assign({}, versionAcc.paths), defs.paths),
            schemas: __assign(__assign({}, versionAcc.schemas), defs.schemas)
        }); });
    }));
};
var generateDoc = function (config, getDocumentation) {
    var _a = getPaths(config.endpoints, getDocumentation), paths = _a.paths, schemas = _a.schemas;
    var modelSchema = (0, function_1.pipe)(__assign({ any: t.any }, config.models), R.reduceWithIndex(S.Ord)({
        any: {
            type: 'any',
            description: 'Any value'
        },
        string: {
            type: 'string',
            description: 'A string value'
        },
        url: {
            type: 'string',
            description: 'A valid URL'
        },
        boolean: {
            type: 'boolean',
            description: 'A `true | false` value'
        }
    }, function (key, acc, model) {
        var _a;
        utils_1.swaggerLogger.debug('Model %O', model);
        var modelSchema = (0, IOTSToOpenAPISchema_1.getOpenAPISchema)(model);
        utils_1.swaggerLogger.debug('Model schema %O', modelSchema);
        if (model.name) {
            return __assign(__assign({}, acc), (_a = {}, _a[getInnerSchemaName(model.name)] = modelSchema, _a));
        }
        return acc;
    }));
    utils_1.swaggerLogger.debug('Model schema %O', modelSchema);
    return {
        openapi: '3.0.3',
        info: {
            title: config.title,
            description: config.description,
            version: config.version
        },
        servers: [
            {
                url: "{protocol}://{host}{port}/{basePath}",
                description: 'Node Server',
                variables: {
                    protocol: { "default": config.server.protocol },
                    host: { "default": config.server.host },
                    port: {
                        "default": config.server.port,
                        "enum": [config.server.port, '443']
                    },
                    basePath: {
                        "default": config.server.basePath
                    }
                }
            },
        ],
        security: config.security,
        components: {
            securitySchemes: config.components.security,
            schemas: __assign(__assign({}, schemas), modelSchema)
        },
        paths: paths
    };
};
exports.generateDoc = generateDoc;
