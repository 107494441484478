var _a, _b, _c;
export var config = {
    NODE_ENV: process.env.NODE_ENV,
    PUBLIC_URL: process.env.PUBLIC_URL,
    API_URL: process.env.API_URL,
    DATA_DONATION_FLUSH_INTERVAL: (_a = process.env.DATA_DONATION_FLUSH_INTERVAL) !== null && _a !== void 0 ? _a : '3000',
    DEBUG: (_b = process.env.DEBUG) !== null && _b !== void 0 ? _b : '@YCAI:*:error',
    WEB_URL: process.env.WEB_URL,
    BUILD_DATE: (_c = process.env.BUILD_DATE) !== null && _c !== void 0 ? _c : new Date().toISOString(),
    VERSION: process.env.VERSION,
};
