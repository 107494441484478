var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { isLeft } from 'fp-ts/lib/Either';
import { assignAccessToken } from '../../state/dashboard/creator.commands';
import { doUpdateCurrentView } from '../../utils/location.utils';
var useStyles = makeStyles(function (theme) { return ({
    content: {
        minWidth: '500px',
    },
    error: {
        marginTop: theme.spacing(1),
    }
}); });
var TokenLoginModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var t = useTranslation().t;
    var classes = useStyles();
    var _b = __read(React.useState(''), 2), token = _b[0], setToken = _b[1];
    var _c = __read(React.useState(''), 2), error = _c[0], setError = _c[1];
    var handleSubmit = function () {
        void assignAccessToken({ token: token })().then(function (resp) {
            if (isLeft(resp)) {
                setError(t('link_account:token_authentication_failed'));
            }
            else {
                onClose();
                void doUpdateCurrentView({ view: 'lab' })();
            }
        });
    };
    var handleKeyDown = function (event) {
        if (event.key === 'Enter') {
            void handleSubmit();
        }
    };
    return (React.createElement(Dialog, { open: isOpen, onClose: onClose },
        React.createElement(DialogTitle, null, t('link_account:token_modal_title')),
        React.createElement(DialogContent, { className: classes.content },
            React.createElement(DialogContentText, null, t('link_account:token_modal_description')),
            React.createElement(TextField, { fullWidth: true, label: t('settings:access_token'), value: token, onChange: function (e) { return setToken(e.target.value); }, onKeyDown: handleKeyDown }),
            error && (React.createElement(DialogContentText, { className: classes.error }, error))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: onClose }, t('actions:cancel')),
            React.createElement(Button, { color: "primary", onClick: handleSubmit }, t('link_account:token_modal_submit')))));
};
export default TokenLoginModal;
