import { Box, Grid } from '@material-ui/core';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { localProfile } from '../../state/dashboard/creator.queries';
import { keypair, settings } from '../../state/dashboard/public.queries';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { LazyFullSizeLoader } from '../common/FullSizeLoader';
import { AccessTokenBox } from './settings/AccessTokenBox';
import { KeypairBox } from './settings/KeypairBox';
import { Swagger } from './settings/Swagger';
var withQueries = declareQueries({
    settings: settings,
    keypair: keypair,
    profile: localProfile,
});
var Settings = withQueries(function (_a) {
    var queries = _a.queries;
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var settings = _a.settings, keypair = _a.keypair, profile = _a.profile;
        return (React.createElement(Box, null,
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                    React.createElement(AccessTokenBox, { profile: profile }),
                    keypair && (settings === null || settings === void 0 ? void 0 : settings.independentContributions.enable) && (React.createElement(KeypairBox, { keypair: keypair, settings: settings })))),
            React.createElement(Box, { style: { paddingBottom: 100 } },
                React.createElement(Swagger, null))));
    }));
});
export default Settings;
