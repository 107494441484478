import { Grid } from '@material-ui/core';
import * as React from 'react';
import { addRecommendationForVideo } from '../../../../state/dashboard/creator.commands';
import { YTVideo } from '../../../common/YTVideo';
import AddRecommendationBox from '../AddRecommendationBox';
import { VideoRecommendations } from './VideoRecommendations';
export var LabVideoEdit = function (_a) {
    var videoId = _a.videoId;
    var onRecommendationAdd = React.useCallback(function (recommendationURL) {
        void addRecommendationForVideo({
            videoId: videoId,
            recommendationURL: recommendationURL,
        }, {
            videoRecommendations: { videoId: videoId },
        })();
    }, []);
    return (React.createElement(Grid, { container: true, spacing: 4, alignItems: "flex-start" },
        React.createElement(Grid, { container: true, item: true, lg: 6, md: 6, xs: 12, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(YTVideo, { videoId: videoId })),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(AddRecommendationBox, { onAddClick: onRecommendationAdd }))),
        React.createElement(Grid, { item: true, lg: 5, md: 5, xs: 12 },
            React.createElement(VideoRecommendations, { queries: { videoRecommendations: { videoId: videoId } }, videoId: videoId }))));
};
