var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Typography } from '@material-ui/core';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { patchRecommendation, updateRecommendationsForVideo, } from '../../../../state/dashboard/creator.commands';
import * as publicQueries from '../../../../state/dashboard/public.queries';
import { LazyFullSizeLoader } from '../../../common/FullSizeLoader';
import { ReorderList } from '../../../common/ReorderList';
import RecommendationCard from './RecommendationCard';
var withQueries = declareQueries({
    settings: publicQueries.settings,
    videoRecommendations: publicQueries.videoRecommendations,
});
export var VideoRecommendations = withQueries(function (_a) {
    var queries = _a.queries, videoId = _a.videoId;
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var settings = _a.settings, videoRecommendations = _a.videoRecommendations;
        var t = useTranslation().t;
        var recElement = videoRecommendations.length > 0 ? (React.createElement(ReorderList, { spacing: 2, getKey: function (item) { return item.urlId; }, items: videoRecommendations.map(function (v, i) { return (__assign(__assign({}, v), { index: i })); }), compareItem: function (item, dragItem) {
                return item.urlId !== dragItem.urlId;
            }, onDragComplete: function (recommendations) {
                void updateRecommendationsForVideo({
                    videoId: videoId,
                    recommendations: recommendations.map(function (r) { return r.urlId; }),
                }, {
                    videoRecommendations: {
                        videoId: videoId,
                    },
                })();
            }, renderItem: function (item, i) { return (React.createElement(RecommendationCard, { edit: { videoId: videoId }, key: item.urlId, data: item, onEditCompleted: function (r) {
                    void patchRecommendation({
                        urlId: item.urlId,
                        data: {
                            title: r.title,
                            description: r.description,
                        },
                    })().then(function () {
                        return publicQueries.videoRecommendations.invalidate({
                            videoId: videoId,
                        })();
                    });
                }, onDeleteClick: function () {
                    void updateRecommendationsForVideo({
                        videoId: videoId,
                        recommendations: videoRecommendations
                            .map(function (d) { return d.urlId; })
                            .filter(function (dd) { return dd !== item.urlId; }),
                    }, {
                        videoRecommendations: {
                            videoId: videoId,
                        },
                    })();
                }, onMoveUpClick: i > 0 &&
                    (function () {
                        var pos = videoRecommendations.findIndex(function (_a) {
                            var urlId = _a.urlId;
                            return urlId === item.urlId;
                        });
                        var urlIds = videoRecommendations.map(function (_a) {
                            var urlId = _a.urlId;
                            return urlId;
                        });
                        var tmp = urlIds[pos - 1];
                        urlIds[pos - 1] = urlIds[pos];
                        urlIds[pos] = tmp;
                        void updateRecommendationsForVideo({
                            videoId: videoId,
                            recommendations: urlIds,
                        }, {
                            videoRecommendations: {
                                videoId: videoId,
                            },
                        })();
                    }), onMoveDownClick: i < videoRecommendations.length - 1 &&
                    (function () {
                        var pos = videoRecommendations.findIndex(function (_a) {
                            var urlId = _a.urlId;
                            return urlId === item.urlId;
                        });
                        var urlIds = videoRecommendations.map(function (_a) {
                            var urlId = _a.urlId;
                            return urlId;
                        });
                        var tmp = urlIds[pos + 1];
                        urlIds[pos + 1] = urlIds[pos];
                        urlIds[pos] = tmp;
                        void updateRecommendationsForVideo({
                            videoId: videoId,
                            recommendations: urlIds,
                        }, {
                            videoRecommendations: {
                                videoId: videoId,
                            },
                        })();
                    }) })); } })) : (React.createElement("div", null,
            React.createElement(Typography, null, t('recommendations:no_items'))));
        return React.createElement(Box, null, recElement);
    }));
});
