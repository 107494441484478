"use strict";
exports.__esModule = true;
var ts_endpoint_1 = require("ts-endpoint");
var ListMetadata_output_1 = require("../../models/http/metadata/output/ListMetadata.output");
var ListMetadata_query_1 = require("../../models/http/metadata/query/ListMetadata.query");
var ListMetadata = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v2/metadata"; },
    Input: {
        Query: ListMetadata_query_1.ListMetadataQuery
    },
    Output: ListMetadata_output_1.ListMetadataResponse
});
exports["default"] = {
    ListMetadata: ListMetadata
};
