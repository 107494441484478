"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.endpoints = void 0;
var ContributorPersonalStats_1 = require("@shared/models/contributor/ContributorPersonalStats");
var ContributorPersonalSummary_1 = require("@shared/models/contributor/ContributorPersonalSummary");
var CreatorStats_1 = require("@shared/models/CreatorStats");
var PublicKey_1 = require("@shared/models/http/params/PublicKey");
var SearchQuery_1 = require("@shared/models/http/SearchQuery");
var t = __importStar(require("io-ts"));
var ts_endpoint_1 = require("ts-endpoint");
var GetAuthorStatsByVideoId = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var videoId = _a.videoId;
        return "/v1/author/".concat(videoId);
    },
    Input: {
        Params: t.type({ videoId: t.string })
    },
    Output: CreatorStats_1.CreatorStats
});
var GetPersonalStatsByPublicKey = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey;
        return "/v1/personal/".concat(publicKey);
    },
    Input: {
        Query: SearchQuery_1.SearchQuery,
        Params: PublicKey_1.PublicKeyParams
    },
    Output: ContributorPersonalStats_1.ContributorPersonalStats
});
var GetPersonalSummaryByPublicKey = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey;
        return "/v1/personal/".concat(publicKey, "/summary/json");
    },
    Input: {
        Query: SearchQuery_1.SearchQuery,
        Params: PublicKey_1.PublicKeyParams
    },
    Output: ContributorPersonalSummary_1.ContributorPersonalSummary
});
var GetPersonalSearchByPublicKey = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey;
        return "/v1/personal/".concat(publicKey, "/search/json");
    },
    Input: {
        Query: SearchQuery_1.SearchQuery,
        Params: PublicKey_1.PublicKeyParams
    },
    Output: ContributorPersonalSummary_1.ContributorPersonalSearch
});
exports.endpoints = {
    GetAuthorStatsByVideoId: GetAuthorStatsByVideoId,
    GetPersonalStatsByPublicKey: GetPersonalStatsByPublicKey,
    GetPersonalSummaryByPublicKey: GetPersonalSummaryByPublicKey,
    GetPersonalSearchByPublicKey: GetPersonalSearchByPublicKey
};
