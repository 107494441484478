export var defaultParams = {
    ccRelatedUsers: {},
    getExperimentById: {},
    getExperimentList: {
        type: 'comparison',
        key: 'fuffa',
        // this is the default as per 'yarn backend watch'
    },
    personalHomes: {},
    personalSearches: {},
    personalVideos: {},
    personalAds: {},
    tikTokPersonalHTMLSummary: {},
    tikTokPersonalSearch: {},
    tikTokSearches: {},
};
