import * as t from 'io-ts';
import { Settings, getDefaultSettings, } from '@shared/models/extension/Settings';
export var OptInNudgeStatus = t.strict({
    showNudgeTimes: t.array(t.number),
}, 'OptInNudgeStatus');
export var getInitialOptInNudgeStatus = function () { return ({
    showNudgeTimes: [
        Date.now() + 1000 * 60 * 15,
        Date.now() + 1000 * 60 * 60 * 24 * 7,
        Date.now() + 1000 * 60 * 60 * 24 * 7,
        Date.now() + 1000 * 60 * 60 * 24 * 30,
        Date.now() + 1000 * 60 * 60 * 24 * 365, // 2 months from now
    ],
}); };
export { Settings, getDefaultSettings };
