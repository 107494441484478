import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateAccountLinkCompleted } from '../../../../state/dashboard/creator.commands';
import { makeStyles } from '../../../../theme';
import { doUpdateCurrentView } from '../../../../utils/location.utils';
var useStyles = makeStyles(function (theme) { return ({
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    message: {
        fontSize: theme.spacing(4),
        fontWeight: theme.typography.caption.fontWeight,
        marginBottom: 60,
    },
}); });
var Congrats = function (_a) {
    var profile = _a.profile;
    var t = useTranslation().t;
    var classes = useStyles();
    var handleNextClick = function () {
        void updateAccountLinkCompleted({ completed: true })().then(function () {
            return doUpdateCurrentView({ view: 'gemCollection' })();
        });
    };
    return (React.createElement(Grid, { item: true, xs: 8, sm: 6, className: classes.box },
        React.createElement(Typography, { component: "div", className: classes.message }, t('congrats:message')),
        React.createElement(Grid, null,
            React.createElement(Button, { size: "large", variant: "contained", color: "primary", onClick: handleNextClick }, t('congrats:call_to_action_button_message')))));
};
export default Congrats;
