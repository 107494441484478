var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Typography } from '@material-ui/core';
import { Group } from '@vx/group';
import { hierarchy, Treemap, treemapBinary } from '@vx/hierarchy';
import { ParentSize } from '@vx/responsive';
import { scaleLinear } from '@vx/scale';
import { Text } from '@vx/text';
import { defaultStyles, TooltipWithBounds, useTooltip } from '@vx/tooltip';
import React from 'react';
import { makeStyles, useTheme } from '../../../theme';
var defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 };
var useStyles = makeStyles(function (theme) { return ({
    text: {
        '& :hover': {
            cursor: 'pointer',
        },
        '& tspan': {
            textTransform: 'uppercase',
        },
    },
    tooltip: __assign(__assign({}, defaultStyles), { minWidth: 60, backgroundColor: theme.palette.common.black, color: theme.palette.common.white }),
}); });
var TreeMapGraph = function (_a) {
    var width = _a.width, height = _a.height, _b = _a.margin, margin = _b === void 0 ? defaultMargin : _b, data = _a.data;
    var theme = useTheme();
    var classes = useStyles();
    var _c = useTooltip(), tooltipOpen = _c.tooltipOpen, tooltipData = _c.tooltipData, tooltipLeft = _c.tooltipLeft, tooltipTop = _c.tooltipTop, showTooltip = _c.showTooltip, hideTooltip = _c.hideTooltip;
    // const { containerRef, TooltipInPortal } = useTooltipInPortal();
    var maxSize = Math.max.apply(Math, __spreadArray([], __read(data.children.map(function (d) { var _a; return (_a = d.size) !== null && _a !== void 0 ? _a : 0; })), false));
    var fontSizeScale = scaleLinear({
        domain: [0, maxSize],
        range: [12, 38],
    });
    var fontColorScale = scaleLinear({
        domain: [0, maxSize],
        range: [
            theme.palette.common.black,
            theme.palette.common.black,
        ],
    });
    var colorScale = scaleLinear({
        domain: [0, maxSize],
        range: [theme.palette.common.white, theme.palette.primary.main],
    });
    var xMax = width - margin.left - margin.right;
    var yMax = height - margin.top - margin.bottom;
    var root = hierarchy(data).sort(function (a, b) { var _a, _b; return ((_a = b.value) !== null && _a !== void 0 ? _a : 0) - ((_b = a.value) !== null && _b !== void 0 ? _b : 0); });
    return width < 10 ? null : (React.createElement("div", { style: { position: 'relative' } },
        React.createElement("svg", { width: width, height: height },
            React.createElement("rect", { width: width, height: height, rx: 14, fill: 'transparent' }),
            React.createElement(Treemap, { top: margin.top, root: root, size: [xMax, yMax], tile: treemapBinary, round: true }, function (treemap) { return (React.createElement(Group, null, treemap
                .descendants()
                .reverse()
                .map(function (node, i) {
                var _a, _b, _c, _d;
                var nodeWidth = node.x1 - node.x0;
                var nodeHeight = node.y1 - node.y0;
                var fontSize = fontSizeScale((_a = node.value) !== null && _a !== void 0 ? _a : 0);
                var fontColor = fontColorScale((_b = node.value) !== null && _b !== void 0 ? _b : 0);
                var fillColor = (_d = colorScale((_c = node.value) !== null && _c !== void 0 ? _c : 0)) !== null && _d !== void 0 ? _d : theme.palette.common.white;
                var handleMouseMove = function (event) {
                    var top = event.clientY - node.y0 - margin.top - nodeHeight / 2;
                    var left = node.x0 - margin.left - 40 + nodeWidth / 2;
                    showTooltip({
                        tooltipData: node.data,
                        tooltipTop: top,
                        tooltipLeft: left,
                    });
                };
                return (React.createElement(Group, { key: "node-".concat(i), top: node.y0 + margin.top, left: node.x0 + margin.left },
                    node.depth === 1 && (React.createElement("rect", { width: nodeWidth, height: nodeHeight, strokeWidth: 4, fill: fillColor, style: {
                            border: "1px solid ".concat(theme.palette.common.black),
                        }, onMouseEnter: handleMouseMove, onMouseMove: handleMouseMove, onMouseLeave: function () { return hideTooltip(); } })),
                    node.depth === 1 && (React.createElement(Text, { x: nodeWidth / 2, y: nodeWidth / 2, className: classes.text, width: nodeWidth, fontFamily: theme.typography.fontFamily, fontSize: fontSize, fontWeight: 600, fill: fontColor, textAnchor: "middle", verticalAnchor: "middle", lineHeight: "100%" }, node.data.id))));
            }))); })),
        tooltipOpen && tooltipData && (React.createElement(TooltipWithBounds, { key: Math.random(), className: classes.tooltip, top: tooltipTop, left: tooltipLeft },
            React.createElement(Typography, { variant: "subtitle2" }, tooltipData.value)))));
};
var TreeMapGraphResponsive = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 600 : _b, props = __rest(_a, ["height"]);
    return (React.createElement(ParentSize, { style: { height: height } }, function (_a) {
        var width = _a.width, height = _a.height;
        return React.createElement(TreeMapGraph, __assign({}, props, { width: width, height: height }));
    }));
};
export default TreeMapGraphResponsive;
