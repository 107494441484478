var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Button, TextField, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        paddingTop: '0px',
    },
    textField: {
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        '& .MuiFormHelperText-root': {
            backgroundColor: theme.palette.grey[300],
        },
        '& textarea': {
            color: theme.palette.text.secondary,
        },
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    addButton: {
        marginLeft: theme.spacing(3),
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
}); });
var AddRecommendationBox = function (_a) {
    var onAddClick = _a.onAddClick;
    var t = useTranslation().t;
    var _b = __read(React.useState(''), 2), recommendationURL = _b[0], setRecommendationURL = _b[1];
    var classes = useStyles();
    var handleClick = function () { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            onAddClick(recommendationURL);
            setRecommendationURL('');
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Card, { className: classes.root },
        React.createElement(CardContent, { style: { paddingLeft: '0px', paddingTop: '0px' } },
            React.createElement(Typography, { className: classes.title, color: "primary", component: "h2", variant: "h5" }, t('recommendations:add_to_video')),
            React.createElement(Box, { display: "flex", alignItems: "center" },
                React.createElement(TextField, { className: classes.textField, label: t('recommendations:url'), placeholder: t('recommendations:url_placeholder'), multiline: true, value: recommendationURL, onChange: function (v) { return setRecommendationURL(v.target.value); }, variant: "filled", color: "primary", focused: true }),
                React.createElement(Button, { className: classes.addButton, variant: "contained", color: "primary", onClick: handleClick }, t('actions:add'))))));
};
export default AddRecommendationBox;
