var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Box, makeStyles, Typography } from '@material-ui/core';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries } from 'avenger/lib/react';
import { sequenceS } from 'fp-ts/lib/Apply';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import { toAppError } from '@shared/errors/AppError';
import React from 'react';
import { updateAuth, updateProfile, } from '../../state/dashboard/creator.commands';
import { localProfile } from '../../state/dashboard/creator.queries';
import { doUpdateCurrentView } from '../../utils/location.utils';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { LazyFullSizeLoader } from '../common/FullSizeLoader';
import UnlinkProfileButton from '../common/UnlinkProfileButton';
import Avatar from '../external/Avatar';
var useStyles = makeStyles(function (theme) { return ({
    avatar: {
        marginRight: theme.spacing(1),
    },
    username: {
        marginBottom: theme.spacing(1),
        lineHeight: 1,
    },
    channel: {
        display: 'block',
        marginBottom: theme.spacing(1),
        wordBreak: 'break-all',
    },
    unlink: {
        padding: 0,
        lineHeight: 1,
        marginBottom: 0,
    },
}); });
export var LoggedInUserProfileBox = function (_a) {
    var onLogout = _a.onLogout, profile = _a.profile;
    var classes = useStyles();
    return (React.createElement(Box, { display: "flex", alignItems: "flex-start" },
        React.createElement(Avatar, { src: profile.avatar, className: classes.avatar }),
        React.createElement(Box, null,
            React.createElement(Typography, { className: classes.username }, profile.username),
            React.createElement(Typography, { variant: "caption", className: classes.channel },
                "Channel ID:",
                React.createElement("br", null),
                profile.channelId),
            React.createElement(UnlinkProfileButton, { className: classes.unlink, variant: "text", size: "small", onLogout: onLogout }))));
};
var withQueries = declareQueries({ profile: localProfile });
export var UserProfileBox = withQueries(function (_a) {
    var queries = _a.queries;
    var handleChannelDelete = React.useCallback(function () { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            void pipe(sequenceS(TE.ApplicativePar)({
                auth: updateAuth(null),
                profile: updateProfile(null),
            }), TE.chain(function () {
                return pipe(doUpdateCurrentView({ view: 'index' }), TE.mapLeft(toAppError));
            }))();
            return [2 /*return*/];
        });
    }); }, []);
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var profile = _a.profile;
        if (profile === null) {
            return null;
        }
        return (React.createElement(LoggedInUserProfileBox, { profile: profile, onLogout: function () { return handleChannelDelete(); } }));
    }));
});
