var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { available, compose, param, product, queryShallow, queryStrict, } from 'avenger';
import { formatISO, subMonths } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import { AppError, toAppError } from '@shared/errors/AppError';
import { getItem, setItem } from '@shared/providers/localStorage.provider';
import * as sharedConst from '@shared/constants';
import { MakeAPIClient } from '@shared/providers/api.provider';
import * as endpoints from '@yttrex/shared/endpoints';
import { config } from '../../config';
export var CREATOR_CHANNEL_KEY = 'creator-channel';
export var CURRENT_VIDEO_ON_EDIT = 'current-video-on-edit';
export var ACCOUNT_LINK_COMPLETED = 'account-link-completed';
var logout = function () { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                setItem(sharedConst.CONTENT_CREATOR, null)();
                return [4 /*yield*/, profile.invalidate()()];
            case 1:
                _a.sent();
                return [4 /*yield*/, localProfile.invalidate()()];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var API = (_a = MakeAPIClient({
    baseURL: config.API_URL,
    getAuth: function (req) {
        return pipe(localProfile.run(), TE.filterOrElse(function (s) { return s !== null; }, function () { return new Error('Auth is null'); }), TE.fold(function (e) { return function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, Promise.reject(e)];
            });
        }); }; }, function (a) { return function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                req.headers = __assign(__assign({}, req.headers), { 'x-authorization': a.accessToken });
                return [2 /*return*/, req];
            });
        }); }; }))();
    },
    onUnauthorized: function (res) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, logout()];
                case 1:
                    _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
}, endpoints), _a.API), HTTPClient = _a.HTTPClient;
var throwOnMissingProfile = function (profile) {
    return pipe(profile, TE.fromPredicate(function (s) {
        return (s === null || s === void 0 ? void 0 : s.registeredOn) !== undefined && s.accessToken !== undefined;
    }, function () {
        return new AppError('Missing Content Creator', {
            kind: 'ServerError',
            meta: '',
            status: '',
        });
    }));
};
export var auth = queryStrict(function () { return TE.fromIO(getItem(sharedConst.AUTH_KEY)); }, available);
export var accountLinkCompleted = queryStrict(function () { return TE.fromIO(getItem(ACCOUNT_LINK_COMPLETED)); }, available);
// content creator
export var localProfile = queryStrict(function () {
    return TE.fromIO(getItem(sharedConst.CONTENT_CREATOR));
}, available);
export var requiredLocalProfile = compose(product({ profile: localProfile }), queryStrict(function (_a) {
    var profile = _a.profile;
    return pipe(profile, throwOnMissingProfile);
}, available));
export var profile = compose(product({ profile: requiredLocalProfile }), queryStrict(function (_a) {
    var profile = _a.profile;
    return pipe(TE.mapLeft(toAppError)(API.v3.Creator.GetCreator({
        Headers: { 'x-authorization': profile.accessToken },
    })), TE.chain(throwOnMissingProfile));
}, available));
export var creatorRecommendations = compose(product({ profile: requiredLocalProfile, params: param() }), queryStrict(function (_a) {
    var profile = _a.profile;
    return API.v3.Creator.CreatorRecommendations({
        Headers: { 'x-authorization': profile.accessToken },
    });
}, available));
export var creatorVideos = compose(product({ profile: requiredLocalProfile }), queryStrict(function (_a) {
    var profile = _a.profile;
    return API.v3.Creator.CreatorVideos({
        Headers: { 'x-authorization': profile.accessToken },
    });
}, available));
export var oneCreatorVideo = compose(product({
    profile: requiredLocalProfile,
    params: param(),
}), queryShallow(function (_a) {
    var profile = _a.profile, params = _a.params;
    return API.v3.Creator.OneCreatorVideo({
        Headers: { 'x-authorization': profile.accessToken },
        Params: { videoId: params.videoId },
    });
}, available));
export var ccRelatedUsers = compose(product({
    profile: requiredLocalProfile,
    params: param(),
}), queryShallow(function (_a) {
    var profile = _a.profile, params = _a.params;
    return API.v3.Creator.CreatorRelatedChannels({
        Headers: {
            'x-authorization': profile.accessToken,
        },
        Params: {
            channelId: profile.channelId,
        },
        Query: {
            amount: params.amount,
            skip: params.skip,
        },
    });
}, available));
export var creatorStats = compose(product({ profile: requiredLocalProfile }), queryStrict(function (_a) {
    var profile = _a.profile;
    return pipe(API.v3.Creator.GetCreatorStats({
        Params: { channelId: profile.channelId },
    }));
}, available));
export var creatorADVStats = compose(product({ profile: requiredLocalProfile }), queryStrict(function (_a) {
    var profile = _a.profile;
    return pipe(API.v2.Public.GetChannelADVStats({
        Params: {
            channelId: profile.channelId,
            // TODO: move this to params given by caller
        },
        Query: {
            since: formatISO(subMonths(new Date(), 1), {
                representation: 'date',
            }),
            till: formatISO(new Date(), { representation: 'date' }),
        },
    }));
}, available));
