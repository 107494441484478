var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Box, FormControlLabel, Input } from '@material-ui/core';
var makeTextInput = function (_a) {
    var label = _a.label, key = _a.key;
    // eslint-disable-next-line react/display-name
    return function (params, setParams) {
        var _a;
        return (React.createElement(Box, { margin: 2 },
            React.createElement(FormControlLabel, { style: {
                    alignItems: 'flex-start',
                }, labelPlacement: "top", label: label, inputMode: "text", control: React.createElement(Input, { name: key, value: (_a = params[key]) !== null && _a !== void 0 ? _a : '', onChange: function (e) {
                        var _a;
                        return setParams(__assign(__assign({}, params), (_a = {}, _a[key] = e.target.value, _a)));
                    } }) })));
    };
};
export var channelIdInput = makeTextInput({
    label: 'Channel ID',
    key: 'channelId',
});
export var publicKeyInput = makeTextInput({
    label: 'Public Key',
    key: 'publicKey',
});
export var experimentIdInput = makeTextInput({
    label: 'Experiment ID',
    key: 'experimentId',
});
