"use strict";
exports.__esModule = true;
exports.isYTURL = exports.getYTEmbeddingURLById = exports.getYTVideoURLById = exports.getYTMaxResThumbnailById = exports.getYTThumbnailById = exports.getVideoId = void 0;
function getVideoId(locationhref) {
    var _a, _b;
    var p = new URLSearchParams(locationhref.split('?')[1]);
    return (_b = (_a = p.get('v')) !== null && _a !== void 0 ? _a : p.get('watch')) !== null && _b !== void 0 ? _b : undefined;
}
exports.getVideoId = getVideoId;
var getYTThumbnailById = function (id) {
    return "https://i.ytimg.com/vi/".concat(id, "/hqdefault.jpg");
};
exports.getYTThumbnailById = getYTThumbnailById;
var getYTMaxResThumbnailById = function (id) {
    return "https://i.ytimg.com/vi_webp/".concat(id, "/maxresdefault.webp");
};
exports.getYTMaxResThumbnailById = getYTMaxResThumbnailById;
var getYTVideoURLById = function (id) {
    return "https://youtu.be/".concat(id);
};
exports.getYTVideoURLById = getYTVideoURLById;
var getYTEmbeddingURLById = function (id) {
    return "https://www.youtube-nocookie.com/embed/".concat(id);
};
exports.getYTEmbeddingURLById = getYTEmbeddingURLById;
var isYTURL = function (url) {
    return url.includes('youtube.com/') || url.includes('youtu.be/');
};
exports.isYTURL = isYTURL;
