"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.HandshakeResponse = exports.HandshakeBody = void 0;
var t = __importStar(require("io-ts"));
var DateFromISOString_1 = require("io-ts-types/lib/DateFromISOString");
var StringOrNull_1 = require("./common/StringOrNull");
exports.HandshakeBody = t.type({
    config: t.strict({
        publicKey: t.string,
        execount: t.union([t.number, t.undefined]),
        researchTag: t.union([t.string, t.undefined]),
        experimentId: t.union([t.string, t.undefined]),
        testTime: t.union([DateFromISOString_1.DateFromISOString, t.undefined])
    }, 'Config'),
    href: t.string
}, 'HandshakeBody');
exports.HandshakeResponse = t.union([
    t.strict({
        _id: t.string,
        href: t.string,
        execount: t.union([t.number, t.undefined]),
        testName: StringOrNull_1.StringOrNull,
        publicKey: t.string,
        status: t.literal('active'),
        since: t.string
    }),
    t.type({ ignored: t.boolean }),
    t["null"],
], 'HandshakeResponse');
