var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
/**
 * A component that behaves exactly like the HTML img tag,
 * except that if it fails to load the image on the first try,
 * it will try again with the "crossorigin" attribute set
 * to "anonymous".
 *
 * The extraImgProps is an object containing props that
 * will be passed to the img tag directly.
 *
 * I chose this order of execution because I think it's more likely that
 * the image will succeed to load on the first try.
 *
 * E.g. YouTube pictures load without the "crossorigin" attribute,
 * while wikipedia images fail to load without it.
 *
 * This is because without the "anonymous" attribute YouTube returns a
 * "cross-origin-resource-policy: cross-origin" header, so it's fine for us,
 * while wikipedia returns a "access-control-allow-origin: *" header, which
 * triggers an error unless we send an "origin" header with the request.
 *
 * And we cannot just blindly set "crossorigin = 'anonymous'" because
 * if we do that, the image will fail to load from servers where this
 * is not needed.
 */
var Image = function (props) {
    var _a = __read(useState('loading-default'), 2), state = _a[0], setState = _a[1];
    var extraImgProps = props.extraImgProps, imgProps = __rest(props, ["extraImgProps"]);
    var onError = function () {
        if (state === 'loading-default') {
            // if the image did not load the normal way,
            // try again with the cross-origin attribute
            // set to "anonymous"
            setState('loading-anonymous');
        }
        else {
            // if we get an error on the second try,
            // the image is not available or not accessible,
            // we give up
            setState('failed');
        }
    };
    // the crossOrigin prop depending on the state
    var crossOrigin = state === 'loading-anonymous' ? 'anonymous' : undefined;
    return (React.createElement("img", __assign({}, imgProps, (extraImgProps !== null && extraImgProps !== void 0 ? extraImgProps : {}), { onError: onError, crossOrigin: crossOrigin })));
};
export var GEM_PLACEHOLDER_BLACK = '/placeholder_black.png';
export var GEM_PLACEHOLDER_WHITE = '/placeholder_white.png';
export var ImageWithGemPlaceholder = function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? 'black' : _c, props = __rest(_a, ["variant"]);
    return (React.createElement(Image, __assign({}, props, { src: (_b = props.src) !== null && _b !== void 0 ? _b : (variant === 'black' ? GEM_PLACEHOLDER_BLACK : GEM_PLACEHOLDER_WHITE) })));
};
export default Image;
