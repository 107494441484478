"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.Step = exports.OpenURLStep = exports.OpenURLStepType = exports.CustomStep = exports.CustomStepType = exports.ClickStep = exports.ClickType = exports.KeypressStep = exports.KeyPressType = exports.ScrollStep = exports.ScrollStepType = void 0;
var t = __importStar(require("io-ts"));
// todo: this should be named CreateExperimentBody
exports.ScrollStepType = t.literal('scroll');
exports.ScrollStep = t.strict({
    type: exports.ScrollStepType,
    incrementScrollByPX: t.number,
    totalScroll: t.number,
    interval: t.union([t.number, t.undefined])
}, 'ScrollStep');
exports.KeyPressType = t.literal('keypress');
exports.KeypressStep = t.strict({
    type: exports.KeyPressType,
    key: t.string,
    times: t.union([t.number, t.undefined]),
    delay: t.union([t.number, t.undefined]),
    text: t.union([t.string, t.undefined])
}, 'KeypressStep');
exports.ClickType = t.literal('click');
exports.ClickStep = t.strict({
    type: exports.ClickType,
    selector: t.string,
    delay: t.union([t.number, t.undefined])
}, 'ClickStep');
exports.CustomStepType = t.literal('custom');
exports.CustomStep = t.strict({
    type: exports.CustomStepType,
    handler: t.string
}, 'CustomStep');
exports.OpenURLStepType = t.literal('openURL');
exports.OpenURLStep = t.strict({
    type: t.union([exports.OpenURLStepType, t.undefined]),
    title: t.union([t.string, t.undefined]),
    url: t.string,
    urltag: t.union([t.string, t.undefined]),
    watchFor: t.union([t.number, t.string, t.undefined]),
    loadFor: t.union([t.number, t.string, t.undefined]),
    onCompleted: t.union([t.string, t.undefined])
}, 'OpenURLStep');
exports.Step = t.union([
    exports.ScrollStep,
    exports.CustomStep,
    exports.KeypressStep,
    exports.ClickStep,
    // since `openURL` step is the default and the `type` can be `undefined`
    // the `OpenURLStep` codec needs to be the last value of the union
    exports.OpenURLStep,
], 'OpenURL');
