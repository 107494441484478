"use strict";
exports.__esModule = true;
exports.isValidationError = exports.toValidationError = void 0;
var PathReporter_1 = require("io-ts/lib/PathReporter");
var APIError_1 = require("./APIError");
var toValidationError = function (message, errors) {
    var failures = (0, PathReporter_1.failure)(errors);
    // console.log(failure(errors));
    return {
        name: 'APIError',
        message: message,
        status: 400,
        details: {
            kind: 'DecodingError',
            errors: failures
        }
    };
};
exports.toValidationError = toValidationError;
var isValidationError = function (e) {
    return (0, APIError_1.isAPIError)(e) && e.details.kind === 'DecodingError';
};
exports.isValidationError = isValidationError;
