"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.Ad = exports.NatureType = exports.HomeType = exports.VideoType = exports.SearchType = exports.HashtagType = exports.ChannelType = void 0;
var t = __importStar(require("io-ts"));
var date_1 = require("io-ts-types/lib/date");
exports.ChannelType = t.literal('channel');
exports.HashtagType = t.literal('hashtag');
exports.SearchType = t.literal('search');
exports.VideoType = t.literal('video');
exports.HomeType = t.literal('home');
var Nature = t.union([
    t.strict({
        type: exports.ChannelType,
        authorSource: t.string,
        authorName: t.string
    }),
    t.strict({
        type: exports.HashtagType,
        hashtag: t.string
    }),
    t.strict({
        type: exports.SearchType,
        query: t.unknown
    }),
    t.strict({
        type: exports.VideoType,
        videoId: t.string
    }),
    t.strict({
        type: exports.HomeType
    }),
], 'Nature');
exports.NatureType = t.union([exports.ChannelType, exports.HashtagType, exports.SearchType, exports.VideoType, exports.HomeType], 'NatureType');
exports.Ad = t.strict({
    id: t.string,
    href: t.string,
    metadataId: t.string,
    selectorName: t.string,
    sponsoredName: t.union([t.string, t.undefined, t["null"]]),
    sponsoredSite: t.union([t.string, t.undefined, t["null"]]),
    savingTime: date_1.date,
    offsetLeft: t.number,
    offsetTop: t.number,
    nature: Nature
}, 'AdDB');
