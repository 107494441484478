import * as React from 'react';
import { Avatar } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
export var distanceFromNowCell = function (params) {
    return formatDistanceToNow(new Date(params.formattedValue));
};
export var avatarCell = function (params) {
    var _a;
    var src = (_a = params.value) === null || _a === void 0 ? void 0 : _a.toString();
    return React.createElement(Avatar, { src: src });
};
