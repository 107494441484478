import React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Link, useTheme, makeStyles, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getYTMaxResThumbnailById } from '@shared/utils/yt.utils';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiCardContent-root': {
            flexGrow: 1,
        },
        backgroundColor: theme.palette.grey[300],
        '& img:hover': {
            cursor: 'pointer',
        },
        boxShadow: 'none',
    },
    link: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    customBox: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: 2,
        wordBreak: 'keep-all',
        overflow: 'hidden',
    },
    manage: {
        paddingTop: '6px',
        lineHeight: 1,
        minWidth: 0,
        '&:hover': {
            background: 'inherit',
        },
    },
}); });
export var VideoCard = function (_a) {
    var videoId = _a.videoId, title = _a.title, openRecommendations = _a.openRecommendations;
    var t = useTranslation().t;
    var classes = useStyles();
    var theme = useTheme();
    return (React.createElement(Card, { className: classes.root, style: { boxShadow: 'none' } },
        React.createElement(CardMedia, { component: "img", src: getYTMaxResThumbnailById(videoId), title: title, height: 120, onClick: openRecommendations, referrerPolicy: "no-referrer" }),
        React.createElement(CardContent, { style: { paddingBottom: theme.spacing(0.3) }, classes: { root: classes.customBox } },
            React.createElement(Link, { color: "textSecondary", className: classes.link, onClick: openRecommendations, rel: "noreferrer", target: "_blank", underline: "none", variant: "subtitle2" }, title)),
        React.createElement(CardActions, { style: {
                paddingLeft: theme.spacing(1.5),
                paddingTop: theme.spacing(0),
            } },
            React.createElement(Button, { color: "primary", variant: "text", size: "small", className: classes.manage, onClick: openRecommendations }, t('actions:manage_recommendations')))));
};
