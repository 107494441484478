var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Box, Button, Chip, FormControl, Grid, Input, InputLabel, Link, Typography, } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import useCopyClipboard from 'react-use-clipboard';
import { isLeft } from 'fp-ts/lib/Either';
import { Trans, useTranslation } from 'react-i18next';
import { registerCreatorChannel, updateAuth, verifyChannel, } from '../../state/dashboard/creator.commands';
import { makeStyles } from '../../theme';
import TokenLoginModal from './TokenLoginModal';
import { doUpdateCurrentView } from '../../utils/location.utils';
var youtubeChannelUrlRegex = /\/channel\/([^/]+)(?:$|\/)/;
var useStyles = makeStyles(function (theme) { return ({
    box: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    boxGrid: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3),
    },
    channelInput: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(3),
    },
    tokenDisplay: {
        backgroundColor: theme.palette.grey[200],
        display: 'inline-block',
        padding: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    stepAction: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: theme.spacing(5),
        '& button': {
            marginRight: theme.spacing(4),
        },
    },
    linkButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    list: {
        '& li': {
            marginBottom: theme.spacing(1),
        },
    },
    errorBox: {
        display: 'inline-block',
    },
}); });
export var LinkAccount = function (_a) {
    var _b, _c;
    var auth = _a.auth;
    var t = useTranslation().t;
    var _d = __read(useCopyClipboard((_b = auth === null || auth === void 0 ? void 0 : auth.tokenString) !== null && _b !== void 0 ? _b : '', {
        successDuration: 2000,
    }), 2), isCopied = _d[0], setCopied = _d[1];
    var _e = __read(React.useState((_c = auth === null || auth === void 0 ? void 0 : auth.channelId) !== null && _c !== void 0 ? _c : ''), 2), channel = _e[0], setChannel = _e[1];
    var _f = __read(React.useState(false), 2), submitChannelFailed = _f[0], setSubmitChannelFailed = _f[1];
    var _g = __read(React.useState(false), 2), verificationFailed = _g[0], setVerificationFailed = _g[1];
    var _h = __read(React.useState(false), 2), verifying = _h[0], setVerifying = _h[1];
    var _j = __read(React.useState(false), 2), showTokenLoginModal = _j[0], setShowTokenLoginModal = _j[1];
    var channelIDPasted = (auth === null || auth === void 0 ? void 0 : auth.tokenString) !== undefined;
    var classes = useStyles();
    var handleChannelChange = function (e) {
        var youtubeChannelUrlMatch = e.target.value.match(youtubeChannelUrlRegex);
        if (youtubeChannelUrlMatch !== null) {
            setChannel(youtubeChannelUrlMatch[1]);
            return;
        }
        setChannel(e.target.value);
    };
    var handleChannelSubmit = function () { return __awaiter(void 0, void 0, Promise, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, registerCreatorChannel(channel)()];
                case 1:
                    resp = _a.sent();
                    setSubmitChannelFailed(isLeft(resp));
                    return [2 /*return*/];
            }
        });
    }); };
    var onChannelKeyDown = function (e) {
        if (e.key === 'Enter') {
            void handleChannelSubmit();
        }
    };
    var handleVerifyChannelClicked = function () {
        setVerifying(true);
        setVerificationFailed(false);
        if ((auth === null || auth === void 0 ? void 0 : auth.channelId) !== undefined) {
            verifyChannel({
                channelId: auth.channelId,
            })()
                .then(function (res) {
                if (isLeft(res)) {
                    setVerificationFailed(true);
                }
            }, function () {
                setVerificationFailed(true);
            })
                .finally(function () {
                setVerifying(false);
                void doUpdateCurrentView({
                    view: 'lab',
                })();
            });
        }
    };
    var handleGoBackToStepOneClicked = function () {
        setVerificationFailed(false);
        setSubmitChannelFailed(false);
        setChannel('');
        void updateAuth(null)();
    };
    return !channelIDPasted ? (React.createElement(Box, { className: classes.box },
        React.createElement(Typography, { color: "primary", variant: "h5" },
            "1/2 ",
            t('link_account:paste_channel_url')),
        React.createElement(Grid, { className: classes.boxGrid, container: true, spacing: 2, alignItems: "flex-end" },
            React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                React.createElement(FormControl, { className: classes.channelInput },
                    React.createElement(InputLabel, { htmlFor: "creator-channel" }, t('account:channel')),
                    React.createElement(Input, { id: "creator-channel", fullWidth: true, value: channel, onChange: handleChannelChange, onKeyDown: onChannelKeyDown })),
                React.createElement(Typography, null,
                    React.createElement(Trans, { i18nKey: "link_account:already_have_token" },
                        "Or",
                        React.createElement(Link, { className: classes.linkButton, onClick: function () { return setShowTokenLoginModal(true); } }, "click here"),
                        "if you already have an access token.")),
                showTokenLoginModal && (React.createElement(TokenLoginModal, { isOpen: showTokenLoginModal, onClose: function () { return setShowTokenLoginModal(false); } }))),
            React.createElement(Grid, { item: true, xs: 12, className: classes.stepAction },
                React.createElement(Button, { disabled: channel.length < 5, variant: "contained", size: "large", color: "primary", onClick: handleChannelSubmit }, t('actions:next')),
                submitChannelFailed && (React.createElement(Box, { className: classes.errorBox },
                    React.createElement(Typography, null, t('link_account:channel_not_found')))))))) : (React.createElement(Box, { className: classes.box },
        React.createElement(Typography, { variant: "h5", color: "primary" },
            "2/2 ",
            t('link_account:copy_verification_key')),
        React.createElement(Grid, { container: true, className: classes.boxGrid, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { className: classes.tokenDisplay, id: "account-channelId" }, auth.tokenString),
                isCopied ? (React.createElement(Chip, { color: "secondary", label: t('actions:copied') })) : (React.createElement(Button, { color: "primary", variant: "text", startIcon: React.createElement(CopyIcon, null), onClick: function () {
                        setCopied();
                    } }, t('actions:copy_verification_code')))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, null,
                    React.createElement(Trans, { i18nKey: "link_account:verification_code_hint" },
                        "Click",
                        React.createElement(Link, { target: "_blank", rel: "noreferrer", href: "https://studio.youtube.com/channel/".concat(channel, "/editing/details") }, "here to access to your YouTube Studio"),
                        "and edit your channel description. Just paste the link anywhere in it and click the Publish button on the top right. You can remove the code from your channel's description after the verification is finished."))),
            React.createElement(Grid, { item: true, xs: 12, className: classes.stepAction },
                React.createElement(Button, { disabled: verifying, variant: "contained", color: "primary", size: "large", onClick: handleVerifyChannelClicked }, t('actions:verify_channel')),
                React.createElement(Button, { disabled: verifying, variant: "contained", color: "default", size: "large", onClick: handleGoBackToStepOneClicked }, t('actions:clear')),
                verificationFailed && (React.createElement(Box, { className: classes.errorBox },
                    React.createElement(Typography, null, t('link_account:verification_failed')),
                    React.createElement("ul", { className: classes.list },
                        React.createElement("li", null,
                            React.createElement(Typography, null, t('link_account:verification_failed_hint'))),
                        React.createElement("li", null,
                            React.createElement(Typography, null,
                                React.createElement(Trans, { i18nKey: "link_account:go_back_to_step_one_hint" },
                                    "If the verification keeps failing,",
                                    React.createElement(Link, { className: classes.linkButton, onClick: handleGoBackToStepOneClicked }, "go back to step one"),
                                    "and make sure you have pasted the correct URL to your YouTube channel."))))))))));
};
