import { Box, Grid } from '@material-ui/core';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import * as QR from 'avenger/lib/QueryResult';
import { WithQueries } from 'avenger/lib/react';
import * as React from 'react';
import { addRecommendation, deleteRecommendation, } from '../../../../state/dashboard/creator.commands';
import * as queries from '../../../../state/dashboard/creator.queries';
import { LazyFullSizeLoader } from '../../../common/FullSizeLoader';
import AddRecommendationBox from '../AddRecommendationBox';
import GemList from './GemList';
var GemCollection = function () {
    var handleRecommendationAdd = React.useCallback(function (url) {
        void addRecommendation({
            url: url,
        })();
    }, []);
    var handleRecommendationDelete = React.useCallback(function (r) {
        void deleteRecommendation({
            urlId: r.urlId,
        })();
    }, []);
    return (React.createElement(WithQueries, { queries: { recommendations: queries.creatorRecommendations }, render: QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
            var recommendations = _a.recommendations;
            return (React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 12, lg: 6 },
                    React.createElement(AddRecommendationBox, { onAddClick: handleRecommendationAdd })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, null,
                        React.createElement(GemList, { recommendations: recommendations, onDeleteClick: handleRecommendationDelete })))));
        }) }));
};
export default GemCollection;
