"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CreatorStats = exports.CreatorStatContent = void 0;
var t = __importStar(require("io-ts"));
var DateFromISOString_1 = require("io-ts-types/lib/DateFromISOString");
exports.CreatorStatContent = t.strict({
    id: t.string,
    watchedTitle: t.string,
    watchedVideoId: t.string,
    savingTime: DateFromISOString_1.DateFromISOString,
    recommendedVideoId: t.string,
    recommendedViews: t.number,
    recommendedTitle: t.string,
    recommendedChannel: t.string
}, 'CreatorStatContent');
exports.CreatorStats = t.strict({
    authorName: t.string,
    authorSource: t.string,
    overflow: t.boolean,
    total: t.number,
    pagination: t.any,
    stripped: t.union([t.number, t.undefined]),
    content: t.array(exports.CreatorStatContent)
}, 'CreatorStats');
