var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import CSVDownloadButton from '../components/buttons/CSVDownloadButton';
import DeleteButton from '../components/buttons/DeleteButton';
import * as cells from '../components/gridCells';
import * as actions from './actions';
import * as inputs from './inputs';
import * as params from './params';
var columnDefault = {
    minWidth: 200,
};
export var defaultConfiguration = function (commands, params) {
    return {
        ccRelatedUsers: {
            getRowId: function (d) { var _a, _b; return (_b = (_a = d.id) !== null && _a !== void 0 ? _a : d.recommendedSource) !== null && _b !== void 0 ? _b : d.percentage; },
            inputs: inputs.channelIdInput,
            columns: [
                __assign(__assign({}, columnDefault), { field: 'recommendedSource', headerName: 'Recommended Source', minWidth: 160 }),
                __assign(__assign({}, columnDefault), { field: 'percentage', minWidth: 160, valueFormatter: function (p) { return "".concat(p.value, "%"); } }),
                __assign(__assign({}, columnDefault), { field: 'recommendedChannelCount', minWidth: 160 }),
            ],
        },
        getExperimentById: {
            inputs: inputs.experimentIdInput,
            columns: [
                __assign(__assign({}, columnDefault), { field: 'savingTime', headerName: 'savingTime', minWidth: 400, renderCell: cells.distanceFromNowCell }),
            ],
        },
        getExperimentList: {
            columns: [
                __assign(__assign({}, columnDefault), { field: 'experimentId', headerName: 'experimentId', minWidth: 400 }),
                __assign(__assign({}, columnDefault), { field: 'when', headerName: 'Registered', renderCell: cells.distanceFromNowCell }),
                __assign(__assign({}, columnDefault), { field: 'steps', minWidth: 350, renderCell: function (params) {
                        return (React.createElement(Box, { key: params.id }, ((params === null || params === void 0 ? void 0 : params.value) || []).map(function (linkinfo) {
                            return React.createElement("a", { href: linkinfo.url }, linkinfo.urltag);
                        })));
                    } }),
            ],
        },
        personalSearches: {
            inputs: inputs.publicKeyInput,
            actions: function () {
                return (React.createElement(Box, { textAlign: 'right' },
                    React.createElement(CSVDownloadButton, { onClick: function () {
                            void commands.downloadAsCSV({
                                Params: {
                                    publicKey: params.publicKey,
                                    type: 'search',
                                },
                            })();
                        } })));
            },
            columns: [
                __assign(__assign({}, columnDefault), { field: 'id', minWidth: 100 }),
                __assign(__assign({}, columnDefault), { field: 'savingTime', renderCell: cells.distanceFromNowCell }),
                __assign(__assign({}, columnDefault), { field: 'query', minWidth: 350, renderCell: function (params) {
                        return (React.createElement(Typography, { variant: "h6" }, params.formattedValue));
                    } }),
                __assign(__assign({}, columnDefault), { field: 'results', minWidth: 150 }),
                __assign(__assign({}, columnDefault), { field: 'actions', renderCell: function (cellParams) {
                        return (React.createElement(Box, null,
                            React.createElement(DeleteButton, { id: cellParams.row.id, onClick: function (id) {
                                    void commands.deleteContribution({
                                        Params: {
                                            publicKey: params.publicKey,
                                            selector: 'undefined',
                                        },
                                    }, {})();
                                } }),
                            React.createElement(CSVDownloadButton, { onClick: function () {
                                    void commands.downloadSearchesAsCSV({
                                        Params: {
                                            queryString: cellParams.getValue(cellParams.row.id, 'query'),
                                        },
                                    })();
                                } })));
                    } }),
            ],
        },
        personalVideos: {
            inputs: inputs.publicKeyInput,
            actions: function () {
                return (React.createElement(Box, { textAlign: 'right' },
                    React.createElement(CSVDownloadButton, { onClick: function () {
                            void commands.downloadAsCSV({
                                Params: {
                                    publicKey: params.publicKey,
                                    type: 'video',
                                },
                            })();
                        } })));
            },
            columns: [
                __assign(__assign({}, columnDefault), { field: 'relative' }),
                __assign(__assign({}, columnDefault), { field: 'authorName' }),
                __assign(__assign({}, columnDefault), { field: 'authorSource' }),
                __assign(__assign({}, columnDefault), { field: 'actions', renderCell: actions.personalMetadataActions(commands, params) }),
            ],
        },
        personalAds: {
            inputs: inputs.publicKeyInput,
            columns: [],
        },
        personalHomes: {
            inputs: inputs.publicKeyInput,
            columns: [
                __assign(__assign({}, columnDefault), { field: 'id', minWidth: 100 }),
                __assign(__assign({}, columnDefault), { field: 'savingTime', renderCell: cells.distanceFromNowCell }),
                __assign(__assign({}, columnDefault), { field: 'selected', renderCell: function (params) {
                        if (Array.isArray(params.value)) {
                            return params.value.length;
                        }
                        return 0;
                    } }),
                __assign(__assign({}, columnDefault), { field: 'actions', renderCell: actions.personalMetadataActions(commands, params) }),
            ],
        },
        tikTokPersonalHTMLSummary: {
            inputs: inputs.publicKeyInput,
            columns: [
                __assign(__assign({}, columnDefault), { field: 'id' }),
                __assign(__assign({}, columnDefault), { field: 'timelineId' }),
                __assign(__assign({}, columnDefault), { field: 'href' }),
                __assign(__assign({}, columnDefault), { field: 'savingTime', renderCell: cells.distanceFromNowCell }),
            ],
        },
        tikTokPersonalSearch: {
            inputs: inputs.publicKeyInput,
            actions: function () {
                return (React.createElement(Box, { textAlign: 'right' },
                    React.createElement(CSVDownloadButton, { onClick: function () {
                            void commands.downloadAsCSV({
                                Params: {
                                    publicKey: params.publicKey,
                                    type: 'search',
                                },
                            })();
                        } })));
            },
            columns: [
                __assign(__assign({}, columnDefault), { field: 'id', width: 40, renderCell: function (params) {
                        var longId = params.formattedValue;
                        var shortId = longId.substr(0, 7);
                        return (React.createElement("a", { href: "/details/#".concat(encodeURI(longId)) }, shortId));
                    } }),
                __assign(__assign({}, columnDefault), { field: 'query', renderCell: function (params) {
                        return (React.createElement("a", { href: "/search/#".concat(encodeURI(params.formattedValue)) }, params.formattedValue));
                    } }),
                __assign(__assign({}, columnDefault), { field: 'savingTime', headerName: 'when', renderCell: cells.distanceFromNowCell }),
                __assign(__assign({}, columnDefault), { field: 'rejected', headerName: 'was answered?', width: 40, renderCell: function (params) {
                        return React.createElement("span", null, params.formattedValue === true ? '🚫' : '✔️');
                    } }),
                __assign(__assign({}, columnDefault), { field: 'results', width: 40 }),
                __assign(__assign({}, columnDefault), { field: 'sources' }),
            ],
        },
        tikTokSearches: {
            /* this taboule hasn't the CSV allowed nor supported, because
             * it got only a portion of all the searches = the many that
             * have been searched from two users + do not return any rejection */
            columns: [
                __assign(__assign({}, columnDefault), { field: 'id' }),
                __assign(__assign({}, columnDefault), { field: 'query', renderCell: function (params) {
                        return (React.createElement("a", { href: "/search/#".concat(encodeURI(params.formattedValue)) }, params.formattedValue));
                    } }),
                __assign(__assign({}, columnDefault), { field: 'savingTime', renderCell: cells.distanceFromNowCell }),
            ],
        },
    };
};
export { actions, inputs, params };
