"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.endpoints = void 0;
var t = __importStar(require("io-ts"));
var SearchQuery_1 = require("@shared/models/http/SearchQuery");
var ts_endpoint_1 = require("ts-endpoint");
var Auth_1 = require("@shared/models/Auth");
var ChannelRelated_1 = require("@shared/models/ChannelRelated");
var ContentCreator_1 = require("@shared/models/ContentCreator");
var CreatorStats_1 = require("@shared/models/CreatorStats");
var Recommendation = __importStar(require("@shared/models/Recommendation"));
var Video = __importStar(require("@shared/models/Video"));
var GetCreator = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v3/creator/me"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader
    },
    Output: ContentCreator_1.ContentCreator
});
var RegisterCreator = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function (_a) {
        var channelId = _a.channelId;
        return "/v3/creator/".concat(channelId, "/register");
    },
    Input: {
        Params: t.type({ channelId: t.string }),
        Body: ContentCreator_1.RegisterContentCreatorBody
    },
    Output: Auth_1.AuthResponse
});
var VerifyCreator = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function (_a) {
        var channelId = _a.channelId;
        return "/v3/creator/".concat(channelId, "/verify");
    },
    Input: {
        Params: t.type({ channelId: t.string })
    },
    Output: ContentCreator_1.ContentCreator
});
var CreatorVideos = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v3/creator/videos"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader
    },
    Output: ContentCreator_1.ContentCreatorVideosOutput
});
var OneCreatorVideo = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var videoId = _a.videoId;
        return "/v3/creator/videos/".concat(videoId);
    },
    Input: {
        Headers: Auth_1.AuthorizationHeader,
        Params: t.type({ videoId: t.string })
    },
    Output: Video.Video
});
var PullCreatorVideos = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v3/creator/videos/repull"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader
    },
    Output: ContentCreator_1.ContentCreatorVideosOutput
});
var CreatorRecommendations = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v3/creator/recommendations"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader
    },
    Output: Recommendation.RecommendationList
});
var CreatorRelatedChannels = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var channelId = _a.channelId;
        return "/v3/creator/".concat(channelId, "/related");
    },
    Input: {
        Headers: Auth_1.AuthorizationHeader,
        Params: t.type({ channelId: t.string }),
        Query: SearchQuery_1.SearchQuery
    },
    Output: ChannelRelated_1.GetRelatedChannelsOutput
});
var UpdateVideo = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v3/creator/updateVideo"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader,
        Body: Video.UpdateVideoBody
    },
    Output: Video.Video
});
var CreateRecommendation = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v3/creator/ogp"; },
    Input: {
        Headers: Auth_1.AuthorizationHeader,
        Body: Recommendation.CreateRecommendation
    },
    Output: Recommendation.Recommendation
});
// TODO: Swagger
var PatchRecommendation = (0, ts_endpoint_1.Endpoint)({
    Method: 'PATCH',
    getPath: function (_a) {
        var urlId = _a.urlId;
        return "/v3/creator/recommendations/".concat(urlId);
    },
    Input: {
        Params: t.type({ urlId: t.string }),
        Headers: Auth_1.AuthorizationHeader,
        Body: Recommendation.PartialRecommendation
    },
    Output: Recommendation.Recommendation
});
var DeleteRecommendation = (0, ts_endpoint_1.Endpoint)({
    Method: 'DELETE',
    getPath: function (_a) {
        var urlId = _a.urlId;
        return "/v3/creator/recommendations/".concat(urlId);
    },
    Input: {
        Headers: Auth_1.AuthorizationHeader,
        Params: t.type({ urlId: t.string })
    },
    Output: t.boolean
});
var GetCreatorStats = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var channelId = _a.channelId;
        return "/v3/creator/".concat(channelId, "/stats");
    },
    Input: {
        Params: t.type({ channelId: t.string })
    },
    Output: CreatorStats_1.CreatorStats
});
exports.endpoints = {
    GetCreator: GetCreator,
    RegisterCreator: RegisterCreator,
    VerifyCreator: VerifyCreator,
    CreatorVideos: CreatorVideos,
    OneCreatorVideo: OneCreatorVideo,
    CreatorRecommendations: CreatorRecommendations,
    CreateRecommendation: CreateRecommendation,
    PatchRecommendation: PatchRecommendation,
    DeleteRecommendation: DeleteRecommendation,
    CreatorRelatedChannels: CreatorRelatedChannels,
    UpdateVideo: UpdateVideo,
    PullCreatorVideos: PullCreatorVideos,
    GetCreatorStats: GetCreatorStats
};
