"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.MetadataBase = void 0;
var t = __importStar(require("io-ts"));
var date_1 = require("io-ts-types/lib/date");
var DateFromISOString_1 = require("io-ts-types/lib/DateFromISOString");
exports.MetadataBase = t.type({
    id: t.string,
    /**
     * The href where the evidence has been collected
     */
    href: t.string,
    /**
     * The supporter publicKey
     *
     * TODO: it may be replaced by the supporter id
     */
    blang: t.union([t.string, t["null"], t.undefined]),
    supporter: t.string,
    researchTag: t.union([t.string, t.undefined]),
    experimentId: t.union([t.string, t.undefined]),
    /**
     * DB saving time
     */
    clientTime: t.union([date_1.date, DateFromISOString_1.DateFromISOString]),
    savingTime: t.union([date_1.date, DateFromISOString_1.DateFromISOString])
}, 'MetadataBase');
