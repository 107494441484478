import * as sharedConst from '@shared/constants';
import { toAppError } from '@shared/errors/AppError';
import { setItem } from '@shared/providers/localStorage.provider';
import { command } from 'avenger';
import { sequenceS } from 'fp-ts/lib/Apply';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import { API as AuthAPI, auth, creatorRecommendations, creatorVideos, accountLinkCompleted, ACCOUNT_LINK_COMPLETED, localProfile, profile, requiredLocalProfile, } from './creator.queries';
import { settings, videoRecommendations, API as PublicAPI, } from './public.queries';
export var registerCreatorChannel = command(function (channelId) {
    return pipe(PublicAPI.v3.Creator.RegisterCreator({
        Params: { channelId: channelId },
        Body: { type: 'channel' },
    }), TE.chainFirst(function (payload) {
        return TE.fromIO(setItem(sharedConst.AUTH_KEY, payload));
    }));
}, {
    auth: auth,
});
export var verifyChannel = command(function (_a) {
    var channelId = _a.channelId;
    return pipe(PublicAPI.v3.Creator.VerifyCreator({ Params: { channelId: channelId } }), TE.chain(function (cc) { return TE.fromIO(setItem(sharedConst.CONTENT_CREATOR, cc)); }));
}, {
    localProfile: localProfile,
    auth: auth,
    requiredLocalProfile: requiredLocalProfile,
});
export var pullContentCreatorVideos = command(function () {
    return pipe(requiredLocalProfile.run(), TE.chain(function (p) {
        return pipe(AuthAPI.v3.Creator.PullCreatorVideos({
            Headers: {
                'x-authorization': p.accessToken,
            },
        }), TE.mapLeft(toAppError));
    }));
}, {
    creatorVideos: creatorVideos,
});
export var addRecommendation = command(function (_a) {
    var url = _a.url;
    return pipe(profile.run(), TE.chain(function (p) {
        return pipe(AuthAPI.v3.Creator.CreateRecommendation({
            Headers: {
                'x-authorization': p.accessToken,
            },
            Body: { url: url },
        }), TE.mapLeft(toAppError));
    }));
}, {
    creatorRecommendations: creatorRecommendations,
});
export var updateRecommendationsForVideo = command(function (_a) {
    var videoId = _a.videoId, recommendations = _a.recommendations;
    return pipe(requiredLocalProfile.run(), TE.chain(function (p) {
        return pipe(AuthAPI.v3.Creator.UpdateVideo({
            Headers: {
                'x-authorization': p.accessToken,
            },
            Body: {
                videoId: videoId,
                recommendations: recommendations,
            },
        }), TE.mapLeft(toAppError));
    }));
}, {
    settings: settings,
    videoRecommendations: videoRecommendations,
});
export var addRecommendationForVideo = command(function (_a) {
    var videoId = _a.videoId, recommendationURL = _a.recommendationURL;
    return pipe(profile.run(), TE.chain(function (p) {
        return pipe(sequenceS(TE.ApplicativePar)({
            video: AuthAPI.v3.Creator.OneCreatorVideo({
                Headers: {
                    'x-authorization': p.accessToken,
                },
                Params: { videoId: videoId },
            }),
            recommendation: AuthAPI.v3.Creator.CreateRecommendation({
                Headers: {
                    'x-authorization': p.accessToken,
                },
                Body: { url: recommendationURL },
            }),
        }), TE.mapLeft(toAppError));
    }), TE.chain(function (_a) {
        var video = _a.video, recommendation = _a.recommendation;
        if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        video.recommendations.includes(recommendation.urlId)) {
            return TE.right(video);
        }
        return updateRecommendationsForVideo({
            videoId: videoId,
            recommendations: video.recommendations.concat(recommendation.urlId),
        }, {
            videoRecommendations: { videoId: videoId },
        });
    }));
}, {
    videoRecommendations: videoRecommendations,
});
export var patchRecommendation = command(function (_a) {
    var urlId = _a.urlId, data = _a.data;
    return pipe(profile.run(), TE.chain(function (p) {
        return pipe(AuthAPI.v3.Creator.PatchRecommendation({
            Headers: {
                'x-authorization': p.accessToken,
            },
            Params: { urlId: urlId },
            Body: data,
        }), TE.mapLeft(toAppError));
    }));
});
export var deleteRecommendation = command(function (_a) {
    var urlId = _a.urlId;
    return pipe(profile.run(), TE.chain(function (p) {
        return pipe(AuthAPI.v3.Creator.DeleteRecommendation({
            Headers: {
                'x-authorization': p.accessToken,
            },
            Params: { urlId: urlId },
        }), TE.mapLeft(toAppError));
    }));
}, {
    creatorRecommendations: creatorRecommendations,
});
export var updateAuth = command(function (payload) {
    return TE.fromIO(setItem(sharedConst.AUTH_KEY, payload));
}, {
    auth: auth,
});
export var updateAccountLinkCompleted = command(function (_a) {
    var completed = _a.completed;
    return TE.fromIO(setItem(ACCOUNT_LINK_COMPLETED, completed));
}, {
    accountLinkCompleted: accountLinkCompleted,
});
export var updateProfile = command(function (payload) {
    return TE.fromIO(setItem(sharedConst.CONTENT_CREATOR, payload));
}, {
    profile: profile,
    localProfile: localProfile,
});
export var deleteProfile = command(function () { return TE.fromIO(setItem(sharedConst.CONTENT_CREATOR, null)); }, { profile: profile });
export var assignAccessToken = command(function (_a) {
    var token = _a.token;
    return pipe(PublicAPI.v3.Creator.GetCreator({
        Headers: {
            'x-authorization': token,
        },
    }), TE.orElse(function (e) {
        // todo: define a proper NotFound error
        var isNotFoundError = e.message === 'Request failed with status code 500';
        if (isNotFoundError) {
            return TE.left({ message: 'The given access token is not valid.' });
        }
        return TE.left(e);
    }), TE.chain(function (creator) {
        return TE.fromIO(setItem(sharedConst.CONTENT_CREATOR, creator));
    }));
}, {
    localProfile: localProfile,
});
