import { Box, Card, Grid, Link } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/styles';
import { descriptionMaxLength, titleMaxLength, } from '@shared/models/Recommendation';
import { isYTURL } from '@shared/utils/yt.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { patchRecommendation } from '../../../../state/dashboard/creator.commands';
import { creatorRecommendations } from '../../../../state/dashboard/creator.queries';
import { getHostFromURL } from '../../../../utils/location.utils';
import DeleteGemButton from '../../../buttons/DeleteGemButton';
import CharLimitedTypography from '../../../common/CharLimitedTypography';
import { ImageWithGemPlaceholder } from '../../../common/Image';
import EditRecommendation from '../EditRecommendation';
var cardHeight = 140;
var useStyles = makeStyles(function (theme) { return ({
    root: {
        height: cardHeight,
        overflow: 'hidden',
        marginBottom: '10px',
        boxShadow: 'none',
        borderRadius: '8px',
        backgroundColor: theme.palette.background.paper,
        '& a:hover': {
            cursor: 'pointer',
        },
    },
    imageContainer: {
        '& img': {
            height: cardHeight,
            width: '100%',
            objectFit: 'cover',
        },
    },
    body: {
        height: cardHeight,
        overflow: 'hidden',
    },
    right: {
        paddingLeft: theme.spacing(2),
        padding: theme.spacing(2),
        height: "calc(100% - ".concat(theme.spacing(2), "px)"),
    },
    title: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        lineHeight: 1.2,
        lineClamp: 3,
    },
    iconsContainer: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& > *': {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },
    arrowButton: {
        '&:disabled': {
            color: theme.palette.grey[500],
        },
    },
    button: {
        fontWeight: 'bold',
        lineHeight: 1,
        marginRight: theme.spacing(3),
        minWidth: 0,
        padding: 0,
    },
    source: {
        alignItems: 'center',
        color: theme.palette.grey[500],
        display: 'flex',
        fontSize: '0.8rem',
        '& svg': {
            marginTop: 0,
            marginRight: theme.spacing(0.5),
        },
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    clamped: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        wordBreak: 'keep-all',
        overflow: 'hidden',
    },
    description: {
        color: theme.palette.grey[500],
        lineClamp: 3,
    },
}); });
var GemCard = function (_a) {
    var _b;
    var data = _a.data, onDeleteClick = _a.onDeleteClick;
    var t = useTranslation().t;
    var classes = useStyles();
    var isYT = isYTURL(data.url);
    var isExternal = !isYT;
    var handleGemEditCompleted = function (r) {
        void patchRecommendation({
            urlId: r.urlId,
            data: r,
        })().then(function () { return creatorRecommendations.invalidate()(); });
    };
    return (React.createElement(Card, { className: classes.root },
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 5 },
                React.createElement("div", { className: classes.imageContainer },
                    React.createElement(ImageWithGemPlaceholder, { src: data.image, title: data.title }))),
            React.createElement(Grid, { item: true, xs: 6, className: classes.body },
                React.createElement(Box, { className: classes.right, display: "flex", flexDirection: "column" },
                    React.createElement(CharLimitedTypography, { className: "".concat(classes.title, " ").concat(classes.clamped), color: "textSecondary", component: "h6", gutterBottom: true, limit: titleMaxLength, variant: "h6" }, data.title),
                    isExternal && (React.createElement(Link, { href: data.url, target: "_blank", className: classes.source },
                        React.createElement(LinkIcon, null),
                        getHostFromURL(data.url))),
                    React.createElement(Box, { flexGrow: 8, display: "flex", alignItems: "center" },
                        React.createElement(CharLimitedTypography, { className: "".concat(classes.description, " ").concat(classes.clamped), color: "textSecondary", limit: descriptionMaxLength, variant: "body2" }, (_b = data.description) !== null && _b !== void 0 ? _b : t('recommendations:missing_description'))))),
            React.createElement(Grid, { item: true, xs: 1, className: classes.iconsContainer },
                React.createElement(DeleteGemButton, { variant: "icon", data: data, onDeleteClick: onDeleteClick }),
                React.createElement(EditRecommendation, { variant: "icon", data: data, onEditCompleted: handleGemEditCompleted })))));
};
export default GemCard;
