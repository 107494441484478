"use strict";
exports.__esModule = true;
exports.getOpenAPISchema = exports.getInnerSchemaName = void 0;
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-empty-interface */
var Record_1 = require("fp-ts/lib/Record");
var Step_1 = require("../../models/Step");
var utils_1 = require("./utils");
function getProps(codec) {
    switch (codec._tag) {
        case 'InterfaceType':
        case 'PartialType':
            return codec.props;
        case 'ExactType':
            return getProps(codec.type);
    }
}
var objectTypes = ['ExactType', 'InterfaceType', 'PartialType'];
var getInnerSchemaName = function (tt) {
    if (tt.startsWith('Array<')) {
        return tt.replace('Array<', '').replace('>', '');
    }
    return tt;
};
exports.getInnerSchemaName = getInnerSchemaName;
var getOpenAPISchema = function (codec) {
    var type = codec;
    // console.log('type', type._tag);
    if (type.name === 'Array<string>') {
        return {
            type: 'array',
            items: [
                {
                    type: 'string'
                },
            ],
            required: false
        };
    }
    switch (type._tag) {
        case 'AnyType':
            return { type: 'object', description: 'any value' };
        case 'UnknownType':
            return { type: 'object', description: type.name };
        case 'UndefinedType':
        case 'VoidType':
            return { type: 'undefined', description: 'An undefined type' };
        case 'NullType':
            return { type: 'null', description: type.name, nullable: true };
        case 'StringType':
            return { type: 'string', description: type.name };
        case 'NumberType':
            return { type: 'number', format: 'integer32' };
        case 'BooleanType':
            return { type: 'boolean', description: 'A valid boolean type' };
        case 'KeyofType':
            return {
                type: 'string',
                "enum": (0, Record_1.keys)(type.keys)
            };
        case 'LiteralType':
            return { type: 'string', "default": type.value };
        case 'ArrayType':
            return {
                type: 'array',
                description: type.name
                    .replace('Array<', '')
                    .replace('>', '')
                    .concat('[]'),
                items: [
                    {
                        $ref: "#/components/schemas/".concat(type.type.name)
                    },
                ]
            };
        case 'DictionaryType':
            // fc.dictionary(getArbitrary(type.domain), getArbitrary(type.codomain)) as any;
            return {
                type: 'object',
                properties: {}
            };
        case 'InterfaceType':
        case 'PartialType':
        case 'ExactType': {
            var properties = Record_1.record.map(getProps(type), exports.getOpenAPISchema);
            return {
                type: 'object',
                description: type.name,
                properties: properties
            };
        }
        case 'TupleType':
            return { type: 'array', items: type.types.map(exports.getOpenAPISchema) };
        case 'UnionType':
            var nonNullableTypes = type.types.filter(function (tt) { return tt._tag !== 'UndefinedType' && tt._tag !== 'NullType'; });
            var isRequired = type.types.length === nonNullableTypes.length;
            if (nonNullableTypes.every(function (v) { return v._tag === 'LiteralType'; })) {
                return {
                    type: 'string',
                    description: type.name,
                    "enum": nonNullableTypes.map(function (t) { return t.value; }),
                    required: isRequired
                };
            }
            if (nonNullableTypes.length === 1) {
                if (nonNullableTypes[0]._tag === 'BooleanType') {
                    return {
                        type: 'boolean',
                        description: 'A valid boolean type',
                        required: isRequired
                    };
                }
                if (nonNullableTypes[0]._tag === 'StringType') {
                    return {
                        type: 'string',
                        description: nonNullableTypes[0].name,
                        required: isRequired
                    };
                }
            }
            // swaggerLogger.info('Union type %O', nonNullableTypes);
            return {
                type: 'object',
                description: type.name,
                oneOf: nonNullableTypes.map(exports.getOpenAPISchema),
                required: isRequired
            };
        case 'IntersectionType':
            var isObjectIntersection = objectTypes.includes(type.types[0]._tag);
            var schema = isObjectIntersection
                ? {
                    type: 'object',
                    properties: type.types
                        .map(function (t) { return (0, exports.getOpenAPISchema)(t); })
                        .reduce(function (acc, values) {
                        return Object.assign(acc, values.properties);
                    }, {})
                }
                : {
                    type: 'object',
                    properties: type.types.map(function (t) { return (0, exports.getOpenAPISchema)(t); })
                };
            return schema;
        case 'RefinementType': {
            return (0, exports.getOpenAPISchema)(type.type);
        }
        case undefined: {
            if (codec.name === 'DateFromISOString' || codec.name === 'Date') {
                return { type: 'string', format: 'date-time' };
            }
            if (codec.name === 'NumberFromString') {
                return {
                    type: 'number'
                };
            }
            if (codec.name === 'Option<string>') {
                return {
                    type: 'string',
                    required: false
                };
            }
            if (codec.name === 'GetDirectiveOutput') {
                return {
                    type: 'array',
                    items: Step_1.Step.types.map(function (tt) { return ({
                        $ref: "#/components/schemas/".concat(tt.name)
                    }); }),
                    required: true
                };
            }
            if (codec.name === undefined) {
                return {
                    type: 'undefined',
                    description: 'An `undefined` value',
                    required: false
                };
            }
            utils_1.swaggerLogger.warn('unhandled codec %O', codec);
        }
    }
};
exports.getOpenAPISchema = getOpenAPISchema;
