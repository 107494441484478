import * as endpoints from '@yttrex/shared/endpoints';
import models from '@shared/models';
import * as swagger from '@shared/providers/swagger/swagger.provider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SwaggerUI from 'swagger-ui';
import { config } from '../../../config';
import 'swagger-ui/dist/swagger-ui.css';
import '../../../resources/swagger-ui-material.css';
export var Swagger = function () {
    var t = useTranslation().t;
    var ref = React.createRef();
    React.useEffect(function () {
        var apiURL = new URL(config.API_URL);
        var swaggerConfig = swagger.generateDoc({
            title: t('swagger:title'),
            description: t('swagger:description'),
            version: config.VERSION,
            server: {
                protocol: apiURL.protocol === 'http:' ? 'http' : 'https',
                host: apiURL.hostname,
                port: parseInt(apiURL.port, 10),
                basePath: apiURL.pathname.slice(1),
            },
            components: {
                security: {
                    ACTToken: {
                        type: 'apiKey',
                        in: 'header',
                        name: 'X-Authorization',
                    },
                },
            },
            security: [
                {
                    ACTToken: [],
                },
            ],
            endpoints: {
                v1: endpoints.v1,
                v2: endpoints.v2,
                v3: endpoints.v3,
            },
            models: models,
        }, function (e) {
            if (typeof e.description === 'string') {
                return e.description;
            }
            return "".concat(e.Method, ": ").concat(e.getStaticPath(function (a) { return ":".concat(a); }));
        });
        SwaggerUI({
            domNode: ref.current,
            spec: swaggerConfig,
        });
    }, []);
    return React.createElement("div", { ref: ref });
};
