"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ContentCreatorVideosOutput = exports.AuthorizedContentCreator = exports.ContentCreator = exports.RegisterContentCreatorBody = void 0;
var t = __importStar(require("io-ts"));
var DateFromISOString_1 = require("io-ts-types/lib/DateFromISOString");
var Video_1 = require("./Video");
var ChannelType = t.literal('channel');
exports.RegisterContentCreatorBody = t.type({
    type: ChannelType
}, 'RegisterContentCreatorBody');
exports.ContentCreator = t.strict({
    channelId: t.string,
    username: t.union([t.undefined, t.string]),
    avatar: t.union([t.undefined, t.string]),
    accessToken: t.union([t.undefined, t.string]),
    url: t.union([t.undefined, t.string]),
    registeredOn: t.union([t.undefined, DateFromISOString_1.DateFromISOString])
}, 'ContentCreator');
exports.AuthorizedContentCreator = t.strict(__assign(__assign({}, exports.ContentCreator.type.props), { accessToken: t.string }), 'AuthorizedContentCreator');
exports.ContentCreatorVideosOutput = t.array(Video_1.Video, 'ContentCreatorVideos');
