import { Box, Divider, List, ListItem, makeStyles, Typography, useTheme, } from '@material-ui/core';
import { formatDistance, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { doUpdateCurrentView } from '../../utils/location.utils';
import AnalyticsIcon from '../common/icons/AnalyticsIcon';
import LabIcon from '../common/icons/LabIcon';
import SettingsIcon from '../common/icons/SettingsIcon';
import YCAILogo from '../common/YCAILogo';
import { UserProfileBox } from './UserProfileBox';
var useStyles = makeStyles(function (theme) { return ({
    routesList: {
        marginLeft: -theme.spacing(2),
        flexGrow: 1,
    },
    divider: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    listItem: {
        padding: 0,
    },
    listItemNotSelected: {
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    listItemSelected: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: theme.palette.violet.contrastText,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        backgroundColor: "".concat(theme.palette.grey[500]),
        '&:hover': {
            backgroundColor: "".concat(theme.palette.grey[500]),
            opacity: 0.8,
        },
    },
    listItemIcon: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    listItemText: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    subItems: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(2),
        width: "calc(100% - ".concat(theme.spacing(8) + theme.spacing(2), "px)"),
    },
    subListItem: {
        color: theme.palette.grey[400],
        marginTop: theme.spacing(3),
    },
    subListItemSelected: {
        color: theme.palette.violet.main,
    },
    subItemsDivider: {
        marginTop: theme.spacing(2),
    },
}); });
var MenuBox = function (_a) {
    var currentView = _a.currentView;
    var t = useTranslation().t;
    var classes = useStyles();
    var theme = useTheme();
    var menuItems = React.useMemo(function () {
        return [
            {
                title: t('routes:lab_title_short'),
                icon: LabIcon,
                views: ['lab', 'labEdit', 'gemCollection'],
                subItems: [
                    {
                        title: t('routes:gem_collection_title_short'),
                        views: ['gemCollection'],
                    },
                    {
                        title: t('routes:lab_edit_title'),
                        views: ['lab', 'labEdit'],
                    },
                ],
            },
            {
                title: t('routes:analytics_title'),
                icon: AnalyticsIcon,
                views: ['analytics'],
                subItems: [],
            },
            {
                title: t('routes:settings'),
                icon: SettingsIcon,
                views: ['settings'],
                subItems: [],
            },
        ];
    }, []);
    return (React.createElement(List, { className: classes.routesList, disablePadding: true }, menuItems.map(function (menuItem) {
        return (React.createElement(ListItem, { key: menuItem.views[0], className: classes.listItem, button: true, style: { display: 'flex', flexDirection: 'column' } },
            React.createElement(Box, { display: 'flex', flexDirection: "row", alignItems: 'center', width: '100%', className: menuItem.views.includes(currentView.view)
                    ? classes.listItemSelected
                    : classes.listItemNotSelected, onClick: doUpdateCurrentView({ view: menuItem.views[0] }) },
                React.createElement(menuItem.icon, { className: classes.listItemIcon, color: menuItem.views.includes(currentView.view)
                        ? theme.palette.common.white
                        : theme.palette.primary.main }),
                React.createElement(Typography, { variant: "subtitle2", style: {
                        lineHeight: 1,
                        margin: 10,
                        textTransform: 'uppercase',
                    } }, menuItem.title)),
            menuItem.subItems.length > 0 ? (React.createElement(Box, { className: classes.subItems },
                menuItem.subItems.map(function (si) { return (React.createElement(Box, { key: si.title, className: "".concat(si.views.includes(currentView.view)
                        ? classes.subListItemSelected
                        : classes.subListItem, " ").concat(classes.subListItem), onClick: function () {
                        void doUpdateCurrentView({ view: si.views[0] })();
                    } },
                    React.createElement(Typography, { variant: "subtitle2", style: {
                            lineHeight: 1,
                            margin: 0,
                        } }, si.title))); }),
                React.createElement(Divider, { className: classes.subItemsDivider }))) : null));
    })));
};
export var Sidebar = function (_a) {
    var currentView = _a.currentView, accountLinkCompleted = _a.accountLinkCompleted, profile = _a.profile;
    var classes = useStyles();
    var theme = useTheme();
    var t = useTranslation().t;
    var timeAgo = formatDistance(parseISO(config.BUILD_DATE), new Date(), {
        addSuffix: true,
    });
    return (React.createElement(Box, { style: {
            position: 'sticky',
            top: theme.spacing(3),
        } },
        React.createElement(Box, { style: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(8),
            }, onClick: function () {
                void doUpdateCurrentView({ view: 'index' })();
            } },
            React.createElement(YCAILogo, { height: 24 }),
            React.createElement(Box, { style: { marginTop: 10 } },
                React.createElement(Typography, { variant: "caption" }, t('popup:version', { version: config.VERSION, date: timeAgo })))),
        profile && accountLinkCompleted && (React.createElement(React.Fragment, null,
            React.createElement(UserProfileBox, null),
            React.createElement(Divider, { light: true, className: classes.divider }),
            React.createElement(MenuBox, { currentView: currentView })))));
};
