import { command } from 'avenger';
import * as TE from 'fp-ts/lib/TaskEither';
import { setItem } from '@shared/providers/localStorage.provider';
import * as sharedConst from '@shared/constants';
import { keypair, settings, settingsRefetch, API } from './public.queries';
export var handshake = command(function (handshake) {
    return API.v2.Public.Handshake({ Body: handshake });
});
// todo:
export var generateKeypair = command(function () { return TE.right({}); }, {
    keypair: keypair,
});
// todo:
export var deleteKeypair = command(function () { return TE.right({}); }, {
    keypair: keypair,
});
export var updateSettings = command(function (payload) { return TE.fromIO(setItem(sharedConst.SETTINGS_KEY, payload)); }, { keypair: keypair, settings: settings, settingsRefetch: settingsRefetch });
export var downloadTXTFile = command(function (_a) {
    var name = _a.name, data = _a.data;
    return TE.fromIO(function () {
        var downloadEl = document.createElement('a');
        downloadEl.download = name;
        var file = new Blob([data], {
            type: 'application/txt',
        });
        downloadEl.href = window.URL.createObjectURL(file);
        document.body.appendChild(downloadEl);
        downloadEl.click();
        document.body.removeChild(downloadEl);
    });
});
export var refreshSettings = command(function () { return TE.right(undefined); }, {
    settings: settingsRefetch,
});
