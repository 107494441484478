import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import * as React from 'react';
var DeleteButton = function (props) {
    return (React.createElement(Tooltip, { title: "Delete", "aria-label": "Delete", placement: "top" },
        React.createElement(IconButton, { size: "small", onClick: function () {
                props.onClick(props.id);
            } },
            React.createElement(DeleteIcon, { color: "error" }))));
};
export default DeleteButton;
