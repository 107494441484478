var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
var UnlinkProfileButton = function (props) {
    var _a = __read(useState(false), 2), unlinkClicked = _a[0], setUnlinkClicked = _a[1];
    var t = useTranslation().t;
    var onLogout = props.onLogout, buttonProps = __rest(props, ["onLogout"]);
    var handleUnlinkClicked = function () {
        setUnlinkClicked(true);
    };
    var handleUnlinkCancelled = function () {
        setUnlinkClicked(false);
    };
    var handleUnlinkConfirmed = function () {
        setUnlinkClicked(false);
        onLogout();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, __assign({}, buttonProps, { onClick: handleUnlinkClicked }), t('actions:unlink_channel')),
        unlinkClicked && (React.createElement(Dialog, { open: unlinkClicked, onClose: function () { return setUnlinkClicked(false); }, "aria-labelledby": t('actions:unlink_channel'), "aria-describedby": t('actions:unlink_channel') },
            React.createElement(DialogTitle, { id: "unlink-channel-dialog" }, t('actions:unlink_channel')),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, t('actions:unlink_channel_confirm_text'))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleUnlinkConfirmed }, t('actions:unlink_channel_confirm_yes')),
                React.createElement(Button, { onClick: handleUnlinkCancelled, color: "primary" }, t('actions:unlink_channel_confirm_no')))))));
};
export default UnlinkProfileButton;
