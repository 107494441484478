"use strict";
exports.__esModule = true;
var DocumentedEndpoint_1 = require("@shared/endpoints/DocumentedEndpoint");
var Health_1 = require("../../models/Health");
var GetHealth = (0, DocumentedEndpoint_1.DocumentedEndpoint)({
    title: 'GET health',
    description: 'Check server health',
    tags: ['health'],
    Method: 'GET',
    getPath: function () { return "/v0/health"; },
    Output: Health_1.HealthResponse
});
exports["default"] = {
    Public: {
        GetHealth: GetHealth
    }
};
