import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/lib/function';
import { useTranslation } from 'react-i18next';
import { pullContentCreatorVideos } from '../../../state/dashboard/creator.commands';
import { creatorVideos } from '../../../state/dashboard/creator.queries';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { LazyFullSizeLoader } from '../../common/FullSizeLoader';
import { VideoCard } from './VideoCard';
var withQueries = declareQueries({ videos: creatorVideos });
export var CreatorVideos = withQueries(function (_a) {
    var queries = _a.queries, openVideoRecommendations = _a.openVideoRecommendations;
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var videos = _a.videos;
        var t = useTranslation().t;
        useEffect(function () {
            void pullContentCreatorVideos({})();
        }, []);
        return (React.createElement(Grid, { container: true, spacing: 2 }, videos.length === 0 ? (React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, null, t('videos:no_results')))) : (videos.map(function (v) { return (React.createElement(Grid, { item: true, lg: 2, md: 3, sm: 6, xs: 12, key: v.videoId },
            React.createElement(VideoCard, { videoId: v.videoId, title: v.title, openRecommendations: function () { return openVideoRecommendations(v); } }))); }))));
    }));
});
