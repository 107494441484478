"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HashtagMetadata = exports.ProfileMetadata = exports.SearchMetadataResult = exports.SearchMetadata = exports.NativeMetadata = exports.ForYouMetadata = exports.FollowingVideoMetadata = exports.TKMetadataBase = exports.TKMetadata = void 0;
var t = __importStar(require("io-ts"));
var MetadataBase_1 = require("./MetadataBase");
Object.defineProperty(exports, "TKMetadataBase", { enumerable: true, get: function () { return MetadataBase_1.TKMetadataBase; } });
var FollowingMetadata_1 = require("./FollowingMetadata");
Object.defineProperty(exports, "FollowingVideoMetadata", { enumerable: true, get: function () { return FollowingMetadata_1.FollowingVideoMetadata; } });
var ForYouMetadata_1 = require("./ForYouMetadata");
Object.defineProperty(exports, "ForYouMetadata", { enumerable: true, get: function () { return ForYouMetadata_1.ForYouMetadata; } });
var NativeMetadata_1 = require("./NativeMetadata");
Object.defineProperty(exports, "NativeMetadata", { enumerable: true, get: function () { return NativeMetadata_1.NativeMetadata; } });
var ProfileMetadata_1 = require("./ProfileMetadata");
Object.defineProperty(exports, "ProfileMetadata", { enumerable: true, get: function () { return ProfileMetadata_1.ProfileMetadata; } });
var SearchMetadata_1 = require("./SearchMetadata");
Object.defineProperty(exports, "SearchMetadata", { enumerable: true, get: function () { return SearchMetadata_1.SearchMetadata; } });
Object.defineProperty(exports, "SearchMetadataResult", { enumerable: true, get: function () { return SearchMetadata_1.SearchMetadataResult; } });
var HashtagMetadata_1 = require("./HashtagMetadata");
Object.defineProperty(exports, "HashtagMetadata", { enumerable: true, get: function () { return HashtagMetadata_1.HashtagMetadata; } });
exports.TKMetadata = t.union([
    FollowingMetadata_1.FollowingVideoMetadata,
    NativeMetadata_1.NativeMetadata,
    ForYouMetadata_1.ForYouMetadata,
    SearchMetadata_1.SearchMetadata,
    ProfileMetadata_1.ProfileMetadata,
    HashtagMetadata_1.HashtagMetadata,
], 'TKMetadata');
