var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getCurrentView, getDoUpdateCurrentView, } from 'avenger/lib/browser';
var gemCollectionRegex = /^\/lab\/gems$/;
var labEditRegex = /^\/lab\/video\/([^/]+)$/;
var labRegex = /^\/lab\/$/;
var analyticsRegex = /^\/analytics\/$/;
var settingsRegex = /^\/settings\/$/;
var linkAccountRegex = /^\/link-account\/$/;
export function locationToView(location) {
    var _a = location.search, _b = _a.path, currentPath = _b === void 0 ? '' : _b, search = __rest(_a, ["path"]);
    var gemCollectionMatch = currentPath.match(gemCollectionRegex);
    if (gemCollectionMatch !== null) {
        return __assign({ view: 'gemCollection' }, search);
    }
    var labEditViewMatch = currentPath.match(labEditRegex);
    if (labEditViewMatch !== null) {
        return __assign({ view: 'labEdit', videoId: labEditViewMatch[1] }, search);
    }
    var labViewMatch = currentPath.match(labRegex);
    if (labViewMatch !== null) {
        return { view: 'lab' };
    }
    var communityMatch = currentPath.match(analyticsRegex);
    if (communityMatch !== null) {
        return { view: 'analytics' };
    }
    var settingsMatch = currentPath.match(settingsRegex);
    if (settingsMatch !== null) {
        return { view: 'settings' };
    }
    var linkAccountMatch = currentPath.match(linkAccountRegex);
    if (linkAccountMatch !== null) {
        return { view: 'linkAccount' };
    }
    return { view: 'index' };
}
export function viewToLocation(view) {
    switch (view.view) {
        case 'gemCollection':
            return {
                pathname: "index.html",
                search: {
                    path: "/lab/gems",
                },
            };
        case 'labEdit':
            return {
                pathname: "index.html",
                search: {
                    path: "/lab/video/".concat(view.videoId),
                },
            };
        case 'lab':
            return {
                pathname: "index.html",
                search: {
                    path: '/lab/',
                },
            };
        case 'analytics':
            return {
                pathname: "index.html",
                search: {
                    path: '/analytics/',
                },
            };
        case 'settings':
            return {
                pathname: 'index.html',
                search: {
                    path: '/settings/',
                },
            };
        case 'linkAccount':
            return {
                pathname: 'index.html',
                search: {
                    path: '/link-account/',
                },
            };
        case 'index':
            return { pathname: '/index.html', search: {} };
    }
}
export var getHostFromURL = function (url) { return new URL(url).host; };
export var currentView = getCurrentView(locationToView); // ObservableQuery
export var doUpdateCurrentView = getDoUpdateCurrentView(viewToLocation); // Command
