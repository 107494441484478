"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContributionEvent = void 0;
var t = __importStar(require("io-ts"));
var HTMLContributionEvent_1 = require("./HTMLContributionEvent");
var APIRequestContributionEvent_1 = require("./APIRequestContributionEvent");
var SigiStateContributionEvent_1 = require("./SigiStateContributionEvent");
/**
 * Contribution event can be any of:
 *
 * `APIRequestContributionEvent`: the event for tk platform intercepted api requests
 *
 * `HTMLContributionEvent`: the event for the scraped HTML
 *
 * `SigiStateContributionEvent`: the event for the collected SIGI_STATE from html
 */
exports.ContributionEvent = t.union([
    HTMLContributionEvent_1.HTMLContributionEvent,
    APIRequestContributionEvent_1.APIRequestContributionEvent,
    SigiStateContributionEvent_1.SigiStateContributionEvent,
], 'ContributionEvent');
