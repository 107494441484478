var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { Box, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { toValidationError } from '@shared/errors/ValidationError';
import { GetLogger } from '@shared/logger';
import * as QR from 'avenger/lib/QueryResult';
import { WithQueries } from 'avenger/lib/react';
import debug from 'debug';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import * as config from '../config';
import { TabouleDataProvider } from '../state';
import { TabouleQueryKey } from '../state/types';
import { ErrorOverlay } from './ErrorOverlay';
debug.enable((_a = process.env.DEBUG) !== null && _a !== void 0 ? _a : '');
var log = GetLogger('taboule');
var validateProps = function (props) {
    return pipe(TabouleQueryKey.decode(props.query), E.map(function (q) { return (__assign({}, props)); }));
};
export var Taboule = function (_a) {
    var _b;
    var _c = _a.height, height = _c === void 0 ? 600 : _c, props = __rest(_a, ["height"]);
    var propsValidation = validateProps(props);
    if (propsValidation._tag === 'Left') {
        throw toValidationError('Taboule props are invalid', propsValidation.left);
    }
    var _d = propsValidation.right, initialParams = _d.initialParams, queryKey = _d.query, baseURL = _d.baseURL, showInput = _d.showInput, otherProps = __rest(_d, ["initialParams", "query", "baseURL", "showInput"]);
    log.debug("Initial params %O", initialParams);
    var defaultQueryParams = React.useMemo(function () { return config.params.defaultParams[queryKey]; }, [queryKey]);
    log.debug("Default query params %O", defaultQueryParams);
    var _e = __read(React.useState(__assign(__assign({}, defaultQueryParams), initialParams)), 2), params = _e[0], setParams = _e[1];
    var _f = __read(React.useState(0), 2), page = _f[0], setPage = _f[1];
    var _g = __read(React.useState((_b = props.pageSize) !== null && _b !== void 0 ? _b : 25), 2), pageSize = _g[0], setPageSize = _g[1];
    var tabouleQueries = React.useMemo(function () { return TabouleDataProvider(baseURL); }, [baseURL]);
    var query = tabouleQueries.queries[queryKey];
    var _h = React.useMemo(function () {
        return config.defaultConfiguration(tabouleQueries.commands, params)[queryKey];
    }, [queryKey, params]), inputs = _h.inputs, queryConfig = __rest(_h, ["inputs"]);
    var paramsInputs = React.useMemo(function () {
        if (showInput) {
            return inputs === null || inputs === void 0 ? void 0 : inputs(params, setParams);
        }
        return null;
    }, [showInput, queryKey, params]);
    var handlePageSizeChange = React.useCallback(function (pageSize) {
        setPageSize(pageSize);
    }, []);
    var handlePageChange = React.useCallback(function (page) {
        setPage(page);
    }, []);
    var dataGridProps = __assign(__assign(__assign(__assign({}, otherProps), { page: page, filterMode: 'server' }), queryConfig), { rows: [], rowsPerPageOptions: [25, 50, 100], pageSize: pageSize, paginationMode: 'server', components: __assign({ ErrorOverlay: ErrorOverlay, Footer: function () {
                return (React.createElement(Box, { margin: 2 },
                    React.createElement(Typography, null,
                        "Taboule - v",
                        process.env.VERSION)));
            } }, (config.actions !== undefined
            ? {
                Toolbar: function (props) {
                    var _a;
                    return React.createElement(Box, { margin: 2 }, (_a = queryConfig.actions) === null || _a === void 0 ? void 0 : _a.call(queryConfig));
                },
            }
            : {})) });
    log.debug("Rendering with props %O", dataGridProps);
    log.debug("Query %s (%O) with params %O", queryKey, query, params);
    return (React.createElement(Box, { height: height },
        paramsInputs,
        React.createElement(WithQueries, { queries: { query: query }, params: {
                query: {
                    Params: params,
                    Query: {
                        amount: pageSize,
                        skip: page * pageSize,
                    },
                },
            }, render: QR.fold(function () { return (React.createElement(DataGrid, __assign({}, dataGridProps, { loading: true }))); }, function (e) { return (React.createElement(DataGrid, __assign({}, dataGridProps, { error: e }))); }, function (_a) {
                var query = _a.query;
                return (React.createElement(DataGrid, __assign({}, dataGridProps, { page: page, rowCount: query.total, rows: query.content, onPageSizeChange: handlePageSizeChange, onPageChange: handlePageChange })));
            }) })));
};
