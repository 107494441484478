var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Grid } from '@material-ui/core';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
var style = {
    cursor: 'move',
};
var ReorderListItem = function (_a) {
    var item = _a.item, index = _a.index, moveListItem = _a.moveListItem, onDropCompleted = _a.onDropCompleted, renderItem = _a.renderItem;
    var ref = React.useRef(null);
    var _b = __read(useDrop({
        accept: 'Card',
        collect: function (monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop: function (item) {
            onDropCompleted(item);
        },
        hover: function (item, monitor) {
            if (ref.current == null) {
                return;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Time to actually perform the action
            moveListItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    }), 2), handlerId = _b[0].handlerId, drop = _b[1];
    var _c = __read(useDrag(function () { return ({
        type: 'Card',
        item: item,
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }); }), 2), isDragging = _c[0].isDragging, drag = _c[1];
    var opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (React.createElement("div", { ref: ref, style: __assign(__assign({}, style), { opacity: opacity }), "data-handler-id": handlerId }, renderItem(item, index)));
};
export var ReorderList = function (props) {
    var _a;
    var _b = __read(React.useState([]), 2), items = _b[0], setItems = _b[1];
    React.useEffect(function () {
        // update state items with items coming from props
        setItems(props.items);
    }, [props.items]);
    var moveListItem = React.useCallback(function (dragIndex, hoverIndex) {
        var dragItem = items[dragIndex];
        if (dragItem === undefined) {
            return;
        }
        items.splice(dragIndex, 1);
        items.splice(hoverIndex, 0, dragItem);
        setItems(items);
    }, [items]);
    var onDropCompleted = React.useCallback(function (item) {
        props.onDragComplete(items);
    }, [items]);
    return (React.createElement(Grid, { container: true, spacing: (_a = props.spacing) !== null && _a !== void 0 ? _a : 0 }, items.map(function (item, i) { return (React.createElement(Grid, { item: true, xs: 12, key: props.getKey(item) },
        React.createElement(ReorderListItem, { index: i, item: item, moveListItem: moveListItem, onDropCompleted: onDropCompleted, renderItem: props.renderItem }))); })));
};
