import { Box, Card, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Link as LinkIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { descriptionMaxLength, titleMaxLength } from '@shared/models/Recommendation';
import { isYTURL } from '@shared/utils/yt.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getHostFromURL } from '../../../../utils/location.utils';
import DeleteGemButton from '../../../buttons/DeleteGemButton';
import CharLimitedTypography from '../../../common/CharLimitedTypography';
import { ImageWithGemPlaceholder } from '../../../common/Image';
import EditRecommendation from '../EditRecommendation';
var cardHeight = 140;
var useStyles = makeStyles(function (theme) { return ({
    root: {
        height: cardHeight,
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        '& a:hover': {
            cursor: 'pointer',
        },
    },
    imageContainer: {
        '& img': {
            height: cardHeight,
            width: '100%',
            objectFit: 'cover',
        },
    },
    body: {
        height: cardHeight,
        overflow: 'hidden',
    },
    right: {
        paddingLeft: theme.spacing(0.7),
        padding: theme.spacing(2),
        height: "calc(100% - ".concat(theme.spacing(2), "px)"),
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: 1,
        lineClamp: 2,
    },
    iconsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        '& > *': {
            marginRight: theme.spacing(1),
        },
    },
    arrowButton: {
        '&:disabled': {
            color: theme.palette.grey[500],
        },
    },
    button: {
        fontWeight: 'bold',
        lineHeight: 1,
        marginRight: theme.spacing(2),
        minWidth: 0,
        padding: 0,
    },
    source: {
        alignItems: 'center',
        color: theme.palette.grey[500],
        display: 'flex',
        fontSize: '0.8rem',
        '& svg': {
            marginTop: -1,
            marginRight: theme.spacing(0.5),
        },
    },
    clamped: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        wordBreak: 'keep-all',
        overflow: 'hidden',
    },
    description: {
        color: theme.palette.grey[500],
        lineClamp: 3,
    },
}); });
var RecommendationCard = function (_a) {
    var _b;
    var data = _a.data, edit = _a.edit, onEditCompleted = _a.onEditCompleted, onDeleteClick = _a.onDeleteClick, onMoveUpClick = _a.onMoveUpClick, onMoveDownClick = _a.onMoveDownClick;
    var t = useTranslation().t;
    var classes = useStyles();
    var isYT = isYTURL(data.url);
    var isExternal = !isYT;
    return (React.createElement(Card, { className: classes.root },
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 5 },
                React.createElement("div", { className: classes.imageContainer },
                    React.createElement(ImageWithGemPlaceholder, { src: data.image, title: data.title }))),
            React.createElement(Grid, { item: true, xs: 6, className: classes.body },
                React.createElement(Box, { className: classes.right, display: "flex", flexDirection: "column" },
                    React.createElement(CharLimitedTypography, { className: "".concat(classes.title, " ").concat(classes.clamped), color: "textSecondary", component: "h6", gutterBottom: true, limit: titleMaxLength, variant: "h6" }, data.title),
                    isExternal && (React.createElement(Typography, { className: classes.source },
                        React.createElement(LinkIcon, null),
                        getHostFromURL(data.url))),
                    React.createElement(Box, { flexGrow: 1, display: "flex", alignItems: "center" },
                        React.createElement(CharLimitedTypography, { className: "".concat(classes.description, " ").concat(classes.clamped), color: "textSecondary", limit: descriptionMaxLength, variant: "body2" }, (_b = data.description) !== null && _b !== void 0 ? _b : t('recommendations:missing_description'))),
                    React.createElement(Box, null,
                        React.createElement(DeleteGemButton, { data: data, onDeleteClick: onDeleteClick }),
                        React.createElement(EditRecommendation, { className: classes.button, color: "primary", variant: "text", size: "small", data: data, onEditCompleted: onEditCompleted })))),
            React.createElement(Grid, { item: true, xs: 1, className: classes.iconsContainer },
                onMoveUpClick !== undefined && (React.createElement(IconButton, { "aria-label": t('actions:move_recommendation_up'), color: "primary", className: classes.arrowButton, disabled: onMoveUpClick === false, 
                    // there seems to be an eslint bug,
                    // there is no way to get rid of all the warnings whatever I do
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    onClick: onMoveUpClick || undefined, size: "small" },
                    React.createElement(ArrowUpwardIcon, null))),
                onMoveDownClick !== undefined && (React.createElement(IconButton, { "aria-label": t('actions:move_recommendation_down'), color: "primary", className: classes.arrowButton, disabled: onMoveDownClick === false, 
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    onClick: onMoveDownClick || undefined, size: "small" },
                    React.createElement(ArrowDownwardIcon, null)))))));
};
export default RecommendationCard;
