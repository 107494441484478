var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Typography } from '@material-ui/core';
import * as QR from 'avenger/lib/QueryResult';
import { WithQueries } from 'avenger/lib/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { creatorADVStats } from '../../../state/dashboard/creator.queries';
import { makeStyles } from '../../../theme';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { LazyFullSizeLoader } from '../../common/FullSizeLoader';
import TreeMapGraph from '../../common/graphs/TreeMapGraph';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        background: theme.palette.grey[300],
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}); });
export var ADVChannelStatsBox = function () {
    var t = useTranslation().t;
    var classes = useStyles();
    return (React.createElement(Box, { className: classes.root },
        React.createElement(Typography, { color: "primary", variant: "h5" }, t('analytics:advertising_connected_to_your_videos_title')),
        React.createElement(Typography, { color: "primary", variant: "subtitle1" }, t('analytics:advertising_connected_to_your_videos_subtitle')),
        React.createElement(WithQueries, { queries: {
                creatorADVStats: creatorADVStats,
            }, render: QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
                var creatorADVStats = _a.creatorADVStats;
                if (creatorADVStats.length === 0) {
                    return (React.createElement(Typography, { variant: "subtitle1" }, t('analytics:advertising_empty_data')));
                }
                var treeData = __assign({ id: 'ADV' }, creatorADVStats.slice(0, 10).reduce(function (acc, c) { return ({
                    value: acc.value + c.count,
                    children: acc.children.concat({
                        id: c.sponsoredName,
                        value: c.count,
                        size: c.count,
                        parent: 'ADV',
                        children: [],
                    }),
                }); }, { value: 0, children: [] }));
                return React.createElement(TreeMapGraph, { data: treeData });
            }) })));
};
