import { Card, CardContent, Typography } from '@material-ui/core';
import * as NumberUtils from '@shared/utils/number.utils';
import * as React from 'react';
import { makeStyles, useTheme } from '../../theme';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginBottom: 20,
        width: '100%',
        border: function (props) { return "1px solid ".concat(props.color); },
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    content: {
        textAlign: 'center',
        color: function (props) { return props.color; },
    },
}); });
export var StatsCard = function (_a) {
    var header = _a.header, count = _a.count, icon = _a.icon, color = _a.color;
    var theme = useTheme();
    var classes = useStyles({ color: color !== null && color !== void 0 ? color : theme.palette.grey[500] });
    return (React.createElement(Card, { className: classes.root, elevation: 0 },
        React.createElement(CardContent, { className: classes.content, style: { paddingBottom: 0 } }, icon !== null && icon !== void 0 ? icon : null,
            React.createElement(Typography, { variant: "h5" }, header),
            React.createElement(Typography, { variant: "h2", style: { marginBottom: 0 } }, NumberUtils.formatter.format(count)))));
};
