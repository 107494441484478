var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Box, Button, FormControl, FormGroup, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles, Typography, } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteKeypair, downloadTXTFile, generateKeypair, updateSettings, } from '../../../state/dashboard/public.commands';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        marginBottom: theme.spacing(10),
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
}); });
export var KeypairBox = function (_a) {
    var keypair = _a.keypair, settings = _a.settings;
    var t = useTranslation().t;
    var _b = __read(React.useState(false), 2), publicKeyVisible = _b[0], setPublicKeyVisible = _b[1];
    var _c = __read(React.useState(false), 2), privateKeyVisible = _c[0], setPrivateKeyVisible = _c[1];
    var classes = useStyles();
    return (React.createElement(Box, { className: classes.root, style: { width: '100%' } },
        React.createElement(Typography, { component: "h2", color: "textSecondary", variant: "h4" }, t('settings:keypair_title')),
        keypair === null ? (React.createElement(Box, null,
            React.createElement(Button, { variant: "contained", color: "secondary", size: "small", onClick: function () {
                    void generateKeypair({})();
                } }, t('actions:generate_keypair')))) : (React.createElement(FormGroup, null,
            React.createElement(Typography, { color: "textPrimary", gutterBottom: true }, t('settings:keypair_description')),
            React.createElement(Grid, { container: true, className: classes.formControl, spacing: 2, alignItems: "flex-end", justifyContent: "flex-end" },
                React.createElement(Grid, { item: true, xs: 9 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { htmlFor: "keypair-private-key" }, t('settings:keypair_public_key')),
                        React.createElement(Input, { fullWidth: true, value: publicKeyVisible
                                ? keypair.publicKey
                                : keypair.publicKey.substr(0, 5).concat('********'), endAdornment: React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            setPublicKeyVisible(!publicKeyVisible);
                                            setTimeout(function () {
                                                setPublicKeyVisible(publicKeyVisible);
                                            }, 2000);
                                            return [2 /*return*/];
                                        });
                                    }); }, edge: "end" }, publicKeyVisible ? (React.createElement(VisibilityOffIcon, null)) : (React.createElement(VisibilityIcon, null)))) }))),
                React.createElement(Grid, { item: true, xs: 3, style: { textAlign: 'right' } },
                    React.createElement(Button, { color: "secondary", variant: "outlined", size: "small", startIcon: React.createElement(CloudDownload, null), onClick: function () {
                            void downloadTXTFile({
                                name: 'anonymous-identity.txt',
                                data: "publicKey: ".concat(keypair.publicKey, " \n secretKey: ").concat(keypair.secretKey, "\n"),
                            })();
                        } }, t('actions:download'))),
                React.createElement(Grid, { item: true, xs: 9 },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(InputLabel, { htmlFor: "keypair-private-key" }, t('settings:keypair_private_key')),
                        React.createElement(Input, { fullWidth: true, value: privateKeyVisible
                                ? keypair.secretKey
                                : keypair.secretKey.substr(0, 5).concat('********'), endAdornment: React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            setPrivateKeyVisible(!privateKeyVisible);
                                            setTimeout(function () {
                                                setPrivateKeyVisible(privateKeyVisible);
                                            }, 2000);
                                            return [2 /*return*/];
                                        });
                                    }); }, edge: "end" }, privateKeyVisible ? (React.createElement(VisibilityOffIcon, null)) : (React.createElement(VisibilityIcon, null)))) }))),
                React.createElement(Grid, { item: true, xs: 3 })),
            React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(Button, { variant: "outlined", color: "primary", size: "small", onClick: function () {
                            void generateKeypair({})();
                        } }, t('actions:refresh_keypair'))),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(Button, { variant: "outlined", color: "secondary", size: "small", onClick: function () {
                            void deleteKeypair({})();
                            void updateSettings(__assign(__assign({}, settings), { independentContributions: __assign(__assign({}, settings.independentContributions), { enable: false }) }))();
                        } }, t('actions:delete_keypair'))))))));
};
