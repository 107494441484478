import { Button } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { doUpdateCurrentView } from '../../utils/location.utils';
export var LinkAccountButton = function () {
    var t = useTranslation().t;
    return (React.createElement(Button, { onClick: function () {
            return doUpdateCurrentView({
                view: 'linkAccount',
            })();
        } }, t('link_account:title')));
};
