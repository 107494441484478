var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import HelperIcon from '@material-ui/icons/Help';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(2),
            border: 'solid',
            borderWidth: '0px',
            borderColor: theme.palette.secondary.main,
            boxShadow: 'none',
        },
        icon: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    });
});
export default function HelperPopover(_a) {
    var text = _a.text;
    var classes = useStyles();
    var _b = __read(React.useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var handlePopoverOpen = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handlePopoverClose = function () {
        setAnchorEl(null);
    };
    var open = anchorEl !== null;
    return (React.createElement("div", null,
        React.createElement(Typography, { "aria-owns": open ? 'mouse-over-popover' : undefined, "aria-haspopup": "true", onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose },
            React.createElement(HelperIcon, { className: classes.icon, color: "primary" })),
        React.createElement(Popover, { id: "mouse-over-popover", className: classes.popover, classes: {
                paper: classes.paper,
            }, open: open, anchorEl: anchorEl, anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, onClose: handlePopoverClose, disableRestoreFocus: true },
            React.createElement(Typography, null, text))));
}
