"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ContributorPersonalStats = exports.SearchMetadata = exports.VideoMetadata = exports.HomeMetadata = void 0;
var t = __importStar(require("io-ts"));
var StringOrNull_1 = require("../common/StringOrNull");
exports.HomeMetadata = t.strict({
    id: t.string,
    savingTime: t.string,
    selected: t.array(StringOrNull_1.StringOrNull, 'Selected')
}, 'HomeMetadata');
exports.VideoMetadata = t.strict({
    id: t.string,
    videoId: t.string,
    savingTime: t.string,
    title: t.string,
    authorName: t.string,
    authorSource: t.string,
    publicationTime: t.string,
    relatedN: t.number,
    relative: t.string
}, 'VideoMetadata');
exports.SearchMetadata = t.strict({
    id: t.string,
    savingTime: t.string,
    query: t.string,
    results: t.number
}, 'SearchMetadata');
exports.ContributorPersonalStats = t.strict({
    supporter: t.strict({
        _id: t.string,
        publicKey: t.string,
        creationTime: t.string,
        p: t.string,
        lastActivity: t.string,
        version: t.string,
        tag: t.strict({
            id: t.string,
            name: t.string,
            accessibility: t.string,
            lastAccess: t.string,
            description: t.string
        }),
        hereSince: t.string
    }),
    videos: t.array(exports.VideoMetadata),
    homes: t.array(exports.HomeMetadata),
    searches: t.array(exports.SearchMetadata),
    ads: t.array(t.any),
    stats: t.strict({
        home: t.number,
        video: t.number,
        search: t.number
    }),
    request: t.strict({
        amount: t.number,
        skip: t.number,
        when: t.string
    })
}, 'ContributorPersonalStats');
