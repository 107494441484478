var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, CardContent, CardHeader, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { Taboule } from '@taboule/components/Taboule';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries, WithQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { ccRelatedUsers, profile, } from '../../../state/dashboard/creator.queries';
import { makeStyles } from '../../../theme';
import { LazyFullSizeLoader } from '../../common/FullSizeLoader';
import { LinkAccountButton } from '../../common/LinkAccountButton';
import { StatsCard } from '../../common/StatsCard';
import { ADVChannelStatsBox } from './ADVChannelStatsBox';
import { DonutChart } from './DonutChart';
var useStyles = makeStyles(function (theme) { return ({
    recommendabilityScore: {
        background: theme.palette.primary.main,
        borderRadius: theme.spacing(1),
        '& .MuiCardHeader-content .MuiCardHeader-title': __assign({ color: theme.palette.common.white }, theme.typography.h4),
        '& .MuiCardHeader-content .MuiCardHeader-subheader': __assign({ color: theme.palette.common.white }, theme.typography.h6),
    },
    relatedChannels: {
        boxShadow: 'none',
        background: 'transparent',
        '& .MuiCardHeader-content .MuiCardHeader-title': __assign({ color: theme.palette.primary.main }, theme.typography.h4),
        '& .MuiCardHeader-content .MuiCardHeader-subheader': __assign({ color: theme.palette.primary.main }, theme.typography.h6),
    },
    recommendations: {
        boxShadow: 'none',
        background: 'transparent',
        '& .MuiCardHeader-content .MuiCardHeader-title': __assign({ color: theme.palette.primary.main }, theme.typography.h4),
        '& .MuiCardHeader-content .MuiCardHeader-subheader': __assign({ color: theme.palette.primary.main }, theme.typography.h6),
    },
}); });
var CreatorAnalyticsPage = function (_a) {
    var profile = _a.profile;
    var classes = useStyles();
    var theme = useTheme();
    var t = useTranslation().t;
    var amount = 25;
    return (React.createElement(Grid, { item: true, md: 12 }, profile === undefined ? (React.createElement(LinkAccountButton, null)) : (React.createElement(WithQueries, { queries: { stats: ccRelatedUsers }, params: { stats: { params: { amount: amount, skip: 0 } } }, render: QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
            var stats = _a.stats;
            return (React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, md: 3, sm: 6 },
                    React.createElement(Card, { className: classes.recommendations },
                        React.createElement(CardHeader, { title: t('analytics:recommendations_title') }),
                        React.createElement(CardContent, null,
                            React.createElement(Grid, { container: true, direction: "column", alignContent: "flex-start", justifyContent: "center" },
                                React.createElement(Grid, { item: true, sm: 12 },
                                    React.createElement(StatsCard, { header: t('analytics:total_recommendations'), count: stats.totalRecommendations })),
                                React.createElement(Grid, { item: true, sm: 12 },
                                    React.createElement(StatsCard, { header: t('analytics:total_contributions'), count: stats.totalContributions, color: theme.palette.primary.main })))))),
                React.createElement(Grid, { item: true, md: 4 },
                    React.createElement(Card, { className: classes.recommendabilityScore },
                        React.createElement(CardHeader, { title: t('analytics:recommendability_score_title'), subheader: t('analytics:recommendability_score_subtitle') }),
                        React.createElement(CardContent, null,
                            React.createElement(DonutChart, { id: "creator-recommendations-score", title: "".concat(stats.score, "%"), data: {
                                    recommended: [stats.score],
                                    other: [100 - stats.score],
                                }, colors: {
                                    recommended: theme.palette.common.white,
                                    other: theme.palette.grey[300],
                                } })))),
                React.createElement(Grid, { item: true, md: 5 },
                    React.createElement(Card, { className: classes.relatedChannels },
                        React.createElement(CardHeader, { title: t('analytics:top_n_cc_related_to_your_channel', {
                                count: amount,
                            }) }),
                        React.createElement(CardContent, { style: { paddingTop: 0 } },
                            React.createElement(Taboule, { height: 500, showInput: false, query: "ccRelatedUsers", baseURL: config.API_URL, initialParams: {
                                    channelId: profile.channelId,
                                } })))),
                React.createElement(Grid, { item: true, md: 12 },
                    React.createElement(ADVChannelStatsBox, null))));
        }) }))));
};
var withQueries = declareQueries({ profile: profile });
export var AnalyticsPage = withQueries(function (_a) {
    var queries = _a.queries;
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var profile = _a.profile;
        return (React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { item: true, md: 12 },
                React.createElement(CreatorAnalyticsPage, { profile: profile }))));
    }));
});
