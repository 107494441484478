"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.AddEventsBody = exports.ContributionEvent = exports.ADVContributionEvent = exports.VideoContributionEvent = void 0;
var t = __importStar(require("io-ts"));
var ContributionBaseEvent = t.strict({
    randomUUID: t.string,
    incremental: t.number,
    clientTime: t.string,
    href: t.string,
    researchTag: t.union([t.string, t.undefined]),
    experimentId: t.union([t.string, t.undefined])
}, 'ContributionBaseEvent');
exports.VideoContributionEvent = t.strict(__assign(__assign({}, ContributionBaseEvent.type.props), { type: t.union([t.literal('video'), t.literal('NewVideo')]), element: t.string, size: t.number }), 'VideoContributionEvent');
exports.ADVContributionEvent = t.strict(__assign(__assign({}, ContributionBaseEvent.type.props), { type: t.literal('leaf'), html: t.string, hash: t.number, offsetTop: t.number, offsetLeft: t.number, selectorName: t.string }), 'ADVContributionEvent');
exports.ContributionEvent = t.union([exports.VideoContributionEvent, exports.ADVContributionEvent], 'ContributionEvent');
exports.AddEventsBody = t.array(exports.ContributionEvent, 'AddEventsBody');
