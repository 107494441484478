var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import c3 from 'c3';
var useStyles = makeStyles(function (theme) { return ({
    donutChart: {
        fontSize: theme.typography.h2.fontSize,
        color: theme.palette.primary.main,
        '& .c3-chart-arcs > .c3-chart-arcs-title': __assign(__assign({}, theme.typography.h2), { fill: theme.palette.common.white }),
    },
}); });
export var DonutChart = function (_a) {
    var id = _a.id, title = _a.title, data = _a.data, colors = _a.colors;
    var donutChardId = "donut-chart-".concat(id);
    var classes = useStyles();
    React.useEffect(function () {
        var columns = Object.entries(data).reduce(function (acc, _a) {
            var _b = __read(_a, 2), k = _b[0], values = _b[1];
            return acc.concat([__spreadArray([k], __read(values), false)]);
        }, []);
        var chartOpts = {
            bindto: "#".concat(donutChardId),
            data: {
                columns: columns,
                type: 'donut',
                colors: colors,
            },
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            interaction: { enabled: false },
            donut: {
                title: title,
                label: {
                    show: false,
                },
                width: 20,
            },
        };
        var chart = c3.generate(chartOpts);
        return function () {
            chart.destroy();
        };
    }, []);
    return React.createElement("div", { id: donutChardId, className: classes.donutChart });
};
