var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, Link, Typography, useTheme, Divider, Box, } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import * as QR from 'avenger/lib/QueryResult';
import { declareQueries } from 'avenger/lib/react';
import { pipe } from 'fp-ts/lib/function';
import { useTranslation } from 'react-i18next';
import { accountLinkCompleted, auth, localProfile, } from '../../state/dashboard/creator.queries';
import { currentView, doUpdateCurrentView, } from '../../utils/location.utils';
import { ErrorBox } from '@shared/components/Error/ErrorBox';
import { LazyFullSizeLoader } from '../common/FullSizeLoader';
import Settings from './Settings';
import { AnalyticsPage } from './community/AnalyticsPage';
import { LinkAccount } from './LinkAccount';
import { Sidebar } from './Sidebar';
import { Lab } from './lab/Lab';
import { LabVideoEdit } from './lab/LabVideoEdit';
import GemCollection from './lab/GemCollection';
import Congrats from './account/congrats';
import HelperPopover from '../common/HelperPopover';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        margin: 0,
        width: '100%',
        minHeight: '100%',
        backgroundColor: theme.palette.background.default,
    },
    labEditTitle: {
        whiteSpace: 'pre-line',
        paddingTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        '& a': {
            color: theme.palette.common.black,
            marginTop: 3,
            marginRight: theme.spacing(1),
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    congratsTitle: {
        whiteSpace: 'pre-line',
        paddingTop: theme.spacing(10),
        fontSize: theme.spacing(5),
        fontWeight: theme.typography.h1.fontWeight,
        lineHeight: 1.4,
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        '& a': {
            color: theme.palette.common.black,
            marginTop: 3,
            marginRight: theme.spacing(1),
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
}); });
var DashboardContent = function (_a) {
    var currentView = _a.currentView, profile = _a.profile, auth = _a.auth, accountLinkCompleted = _a.accountLinkCompleted;
    var t = useTranslation().t;
    var theme = useTheme();
    var classes = useStyles();
    var _b = __read(React.useMemo(function () {
        switch (currentView.view) {
            case 'settings':
                // eslint-disable-next-line react/jsx-key
                return [t('routes:settings'), '', null, React.createElement(Settings, null)];
            default: {
                if (profile === null) {
                    return [
                        t('routes:link_account'),
                        t('link_account:subtitle'),
                        null,
                        // eslint-disable-next-line react/jsx-key
                        React.createElement(LinkAccount, { auth: auth }),
                    ];
                }
                if (!accountLinkCompleted) {
                    return [
                        t('routes:congrats'),
                        t('congrats:subtitle'),
                        null,
                        React.createElement(Congrats, { profile: profile }),
                    ];
                }
                switch (currentView.view) {
                    case 'labEdit':
                        return [
                            t('routes:lab_edit_title'),
                            t('routes:lab_edit_subtitle'),
                            null,
                            // eslint-disable-next-line react/jsx-key
                            React.createElement(LabVideoEdit, { videoId: currentView.videoId }),
                        ];
                    case 'lab':
                        return [
                            t('routes:lab_title'),
                            t('routes:lab_subtitle'),
                            t('routes:lab_helper'),
                            // eslint-disable-next-line react/jsx-key
                            React.createElement(Lab, null),
                        ];
                    case 'analytics':
                        return [
                            t('routes:analytics_title'),
                            t('routes:analytics_subtitle'),
                            t('routes:analytics_helper_text'),
                            // eslint-disable-next-line react/jsx-key
                            React.createElement(AnalyticsPage, null),
                        ];
                    case 'gemCollection':
                    default:
                        return [
                            t('routes:gem_collection_title'),
                            t('routes:gem_collection_subtitle'),
                            t('routes:gem_collection_helper_text'),
                            // eslint-disable-next-line react/jsx-key
                            React.createElement(GemCollection, null),
                        ];
                }
            }
        }
    }, [currentView, profile, accountLinkCompleted, auth]), 4), currentViewLabel = _b[0], currentViewSubtitle = _b[1], helperText = _b[2], currentViewContent = _b[3];
    return (React.createElement(Grid, { container: true, alignContent: "flex-start", style: {
            minHeight: '100%',
        } },
        React.createElement(Grid, { item: true, xs: 12, style: {
                backgroundColor: theme.palette.background.default,
                paddingTop: profile ? 0 : theme.spacing(12),
            } },
            currentView.view === 'labEdit' ? (React.createElement(Typography, { variant: "h3", component: "h1", color: "textSecondary", className: classes.labEditTitle },
                React.createElement(Link, { onClick: doUpdateCurrentView({ view: 'lab' }) },
                    React.createElement(ArrowBackIcon, null)),
                currentViewLabel)) : !accountLinkCompleted ? (React.createElement(Typography, { component: "h1", color: "primary", className: classes.congratsTitle }, currentViewLabel)) : (React.createElement(Typography, { variant: "h3", component: "h1", color: "textSecondary", style: {
                    whiteSpace: 'pre-line',
                    paddingTop: theme.spacing(1),
                } }, currentViewLabel)),
            React.createElement(Box, { display: "flex", alignItems: "center" },
                React.createElement(Typography, { display: "inline", variant: "subtitle1", color: "textPrimary", gutterBottom: true, style: {
                        marginRight: theme.spacing(1),
                        marginBottom: theme.spacing(2),
                    } }, currentViewSubtitle),
                helperText ? React.createElement(HelperPopover, { text: helperText }) : null),
            React.createElement(Divider, { light: true })),
        React.createElement(Grid, { item: true, xs: 12, style: {
                paddingTop: theme.spacing(4),
            } }, currentViewContent)));
};
var withQueries = declareQueries({
    currentView: currentView,
    profile: localProfile,
    auth: auth,
    accountLinkCompleted: accountLinkCompleted,
});
export var Dashboard = withQueries(function (_a) {
    var queries = _a.queries;
    return pipe(queries, QR.fold(LazyFullSizeLoader, ErrorBox, function (_a) {
        var currentView = _a.currentView, profile = _a.profile, auth = _a.auth, accountLinkCompleted = _a.accountLinkCompleted;
        var classes = useStyles();
        return (React.createElement(Grid, { container: true, className: classes.root, spacing: 4 },
            React.createElement(Grid, { item: true, sm: 12, md: 3, lg: 2 },
                React.createElement(Sidebar, { currentView: currentView, accountLinkCompleted: accountLinkCompleted, profile: profile })),
            React.createElement(Grid, { item: true, sm: 12, md: 9, lg: 10 },
                React.createElement(DashboardContent, { currentView: currentView, profile: profile, auth: auth, accountLinkCompleted: accountLinkCompleted }))));
    }));
});
