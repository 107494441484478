var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { TextField } from '@material-ui/core';
var CharLimitedInput = function (_a) {
    var _b;
    var limit = _a.limit, initialValue = _a.value, onChange = _a.onChange, props = __rest(_a, ["limit", "value", "onChange"]);
    var _c = __read(React.useState((_b = initialValue) !== null && _b !== void 0 ? _b : ''), 2), value = _c[0], setValue = _c[1];
    var tooLong = value.length > limit;
    var handleChange = function (event) {
        var newValue = event.target.value;
        setValue(newValue);
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
    };
    return (React.createElement(TextField, __assign({}, props, { helperText: tooLong && "This field cannot exceed ".concat(limit, " characters"), onChange: handleChange, value: value, variant: "outlined" })));
};
export default CharLimitedInput;
