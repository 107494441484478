"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ContributorPersonalSearch = exports.TikTokPSearchMetadata = exports.ContributorPersonalSummary = exports.SummaryMetadata = exports.SummaryHTMLMetadata = void 0;
var t = __importStar(require("io-ts"));
exports.SummaryHTMLMetadata = t.strict({
    id: t.string,
    timelineId: t.string,
    href: t.string,
    savingTime: t.string
}, 'HTMLMetadata');
exports.SummaryMetadata = t.type({
    id: t.string,
    author: t.union([
        t.type({
            link: t.string,
            name: t.string,
            username: t.string
        }, 'Author'),
        t.undefined,
    ]),
    baretext: t.union([t.string, t.undefined]),
    description: t.union([t.string, t.undefined]),
    hashtags: t.union([t.array(t.string, 'Hashtags'), t.undefined]),
    metrics: t.union([
        t.type({
            liken: t.string,
            commentsn: t.union([t.string, t.undefined]),
            sharen: t.string
        }, 'Metrics'),
        t.undefined,
    ]),
    music: t.union([
        t.type({
            url: t.string,
            name: t.string
        }, 'Music'),
        t.undefined,
    ]),
    order: t.number,
    savingTime: t.string,
    stitch: t.union([
        t.type({
            name: t.string,
            user: t.string
        }, 'Stitch'),
        t.undefined,
    ]),
    timelineId: t.string,
    type: t.union([t.literal('foryou'), t.literal('home'), t.literal('following')], 'MetadataType'),
    relative: t.string
}, 'Metadata');
exports.ContributorPersonalSummary = t.strict({
    // supporter: t.strict({
    //   _id: t.string,
    //   publicKey: t.string,
    //   creationTime: t.string,
    //   p: t.string,
    //   lastActivity: t.string,
    //   version: t.string,
    //   tag: t.strict({
    //     id: t.string,
    //     name: t.string,
    //     accessibility: t.string,
    //     lastAccess: t.string,
    //     description: t.string,
    //     // _id: t.string
    //   }),
    //   hereSince: t.string,
    // }),
    htmls: t.array(exports.SummaryHTMLMetadata),
    metadata: t.array(exports.SummaryMetadata),
    counters: t.strict({
        metadata: t.number,
        full: t.number,
        htmlavail: t.number
    })
}, 'ContributorPersonalSummary');
exports.TikTokPSearchMetadata = t.strict({
    id: t.string,
    rejected: t.boolean,
    query: t.string,
    savingTime: t.string,
    results: t.number,
    sources: t.array(t.string)
}, 'PSearchMetadata');
exports.ContributorPersonalSearch = t.strict({
    metadata: t.array(exports.TikTokPSearchMetadata),
    counters: t.strict({
        metadata: t.number
    })
}, 'ContributorPersonalSearch');
