var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { descriptionMaxLength, titleMaxLength, } from '@shared/models/Recommendation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CharLimitedInput from '../../common/CharLimitedInput';
import { ImageWithGemPlaceholder } from '../../common/Image';
import EditIcon from '@material-ui/icons/Edit';
var useClasses = makeStyles(function (theme) { return ({
    image: {
        height: 250,
        marginBottom: theme.spacing(2),
        objectFit: 'cover',
    },
    textField: {
        marginTop: theme.spacing(2),
        '& input': {
            color: theme.palette.common.black,
        },
        '& textarea': {
            color: theme.palette.common.black,
        },
    },
}); });
var EditRecommendation = function (_a) {
    var variant = _a.variant, data = _a.data, onEditCompleted = _a.onEditCompleted, props = __rest(_a, ["variant", "data", "onEditCompleted"]);
    var t = useTranslation().t;
    var _b = __read(useState(false), 2), formIsOpen = _b[0], setFormIsOpen = _b[1];
    var _c = __read(useState(data.title), 2), title = _c[0], setTitle = _c[1];
    var _d = __read(useState(data.description), 2), description = _d[0], setDescription = _d[1];
    var classes = useClasses();
    var handleSubmit = function () {
        onEditCompleted(__assign(__assign({}, data), { title: title, description: description }));
        setFormIsOpen(false);
    };
    var dirty = title !== data.title ||
        (description !== data.description &&
            !(description === '' && data.description === undefined));
    var button = variant === 'icon' ? (React.createElement(IconButton, __assign({}, props, { "aria-label": t('actions:edit_recommendation_form_title'), color: "primary", className: classes.arrowButton, 
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        size: "small", onClick: function () {
            setFormIsOpen(true);
        } }),
        React.createElement(EditIcon, null))) : (React.createElement(Button, __assign({}, props, { variant: "text", onClick: function () {
            setFormIsOpen(true);
        } }), t('actions:edit_recommendation_button')));
    return (React.createElement(React.Fragment, null,
        button,
        formIsOpen && (React.createElement(Dialog, { open: formIsOpen, onClose: function () { return setFormIsOpen(false); } },
            React.createElement(DialogTitle, null, t('actions:edit_recommendation_form_title')),
            React.createElement(ImageWithGemPlaceholder, { src: data.image, alt: data.title, className: classes.image }),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null,
                    React.createElement(Typography, { variant: "h6" }, t('actions:edit_recommendation_description'))),
                React.createElement(CharLimitedInput, { className: classes.textField, fullWidth: true, label: t('recommendations:title'), limit: titleMaxLength, onChange: function (str) { return setTitle(str); }, value: title }),
                React.createElement(CharLimitedInput, { className: classes.textField, fullWidth: true, multiline: true, label: t('recommendations:description'), limit: descriptionMaxLength, onChange: function (str) { return setDescription(str); }, value: description })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: function () { return setFormIsOpen(false); } }, t('actions:cancel')),
                React.createElement(Button, { color: "primary", disabled: !dirty, onClick: handleSubmit }, t('actions:save')))))));
};
export default EditRecommendation;
