"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.ErrorBox = void 0;
var core_1 = require("@material-ui/core");
var lab_1 = require("@material-ui/lab");
var React = __importStar(require("react"));
var APIError_1 = require("../../errors/APIError");
// todo: add NODE_ENV as parameter
var ErrorBox = function (e) {
    var _a, _b;
    // eslint-disable-next-line
    console.log('Displaying error', e);
    var errorName = (0, APIError_1.isAPIError)(e) ? e.name : 'Error';
    var message = (0, APIError_1.isAPIError)(e) ? e.message : 'Unknown Error';
    return (React.createElement(core_1.Card, null,
        React.createElement(lab_1.Alert, { severity: "error" },
            React.createElement(lab_1.AlertTitle, null, errorName),
            React.createElement("p", null, message),
            (0, APIError_1.isAPIError)(e) && ((_a = e.details) === null || _a === void 0 ? void 0 : _a.kind) === 'DecodingError' ? (React.createElement("ul", null, ((_b = e.details.errors) !== null && _b !== void 0 ? _b : []).map(function (d) { return (React.createElement("li", { key: d }, d)); }))) : null),
        React.createElement(core_1.CardContent, null,
            React.createElement(core_1.Typography, { variant: "h6" }, "Debug"),
            React.createElement("pre", { style: { backgroundColor: 'white' } },
                React.createElement("code", null, JSON.stringify(e, null, 2))))));
};
exports.ErrorBox = ErrorBox;
