"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
var Experiment_1 = require("@shared/models/Experiment");
var HandshakeBody_1 = require("@shared/models/HandshakeBody");
var Step_1 = require("@shared/models/Step");
var t = __importStar(require("io-ts"));
var ts_endpoint_1 = require("ts-endpoint");
var Handshake = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v2/handshake"; },
    Input: {
        Body: HandshakeBody_1.HandshakeBody
    },
    Output: t.any
});
var PostDirective = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v2/directives"; },
    Input: {
        Headers: t.type({
            'Content-Type': t.string
        }),
        Body: t.array(Step_1.Step)
    },
    Output: Experiment_1.CreateExperimentResponse
});
var GetDirective = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var experimentId = _a.experimentId;
        return "/v2/directives/".concat(experimentId);
    },
    Input: {
        Params: t.type({
            experimentId: t.string
        })
    },
    Output: t.array(Step_1.Step)
});
var GetPublicDirectives = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v2/directives/public"; },
    Output: Experiment_1.GetPublicDirectivesOutput
});
var Experiments = {
    Handshake: Handshake,
    GetDirective: GetDirective,
    PostDirective: PostDirective,
    GetPublicDirectives: GetPublicDirectives
};
exports["default"] = Experiments;
