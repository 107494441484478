"use strict";
exports.__esModule = true;
exports.setItem = exports.getItem = void 0;
var getItem = function (key) {
    return function () {
        var result = window.localStorage.getItem(key);
        if (typeof result === 'string') {
            return JSON.parse(result);
        }
        return result;
    };
};
exports.getItem = getItem;
var setItem = function (key, value) {
    return function () {
        return window.localStorage.setItem(key, JSON.stringify(value));
    };
};
exports.setItem = setItem;
