"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var t = __importStar(require("io-ts"));
var apiModel = __importStar(require("../../models"));
var common_1 = require("@shared/models/common");
var endpoints_1 = require("@shared/endpoints");
var personal_1 = require("../../models/personal");
var GetPersonalJSON = (0, endpoints_1.DocumentedEndpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey, what = _a.what;
        return "/v2/personal/".concat(publicKey, "/").concat(what, "/json");
    },
    Input: {
        Params: t.type({
            publicKey: t.string,
            what: common_1.What,
        }),
    },
    Output: apiModel.Personal.PersonalVideoList,
    title: 'Personal data (json)',
    description: 'Get your personal data as JSON.',
    tags: ['personal'],
});
var GetPersonalCSV = (0, endpoints_1.DocumentedEndpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey, what = _a.what;
        return "/v2/personal/".concat(publicKey, "/").concat(what, "/csv");
    },
    Input: {
        Params: t.type({
            publicKey: t.string,
            what: common_1.What,
        }),
    },
    Output: t.string,
    title: 'Personal data (csv)',
    description: 'Download your personal data as CSV.',
    tags: ['personal'],
});
var GetPersonalByExperiment = (0, endpoints_1.DocumentedEndpoint)({
    title: 'Personal data by experiment id',
    description: 'Get personal data by the given experiment id',
    tags: ['personal'],
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey, experimentId = _a.experimentId, format = _a.format;
        return "/v2/personal/".concat(publicKey, "/experiment/").concat(experimentId, "/").concat(format);
    },
    Input: {
        Params: t.type({
            publicKey: t.string,
            experimentId: t.string,
            format: common_1.Format,
        }),
    },
    Output: personal_1.PersonalData,
});
exports.default = {
    GetPersonalJSON: GetPersonalJSON,
    GetPersonalCSV: GetPersonalCSV,
    GetPersonalByExperiment: GetPersonalByExperiment,
};
