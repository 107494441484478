import * as t from 'io-ts';
export var TabouleQueryKey = t.union([
    t.literal('ccRelatedUsers'),
    t.literal('getExperimentById'),
    t.literal('getExperimentList'),
    t.literal('personalSearches'),
    t.literal('personalVideos'),
    t.literal('personalHomes'),
    t.literal('personalAds'),
    t.literal('tikTokPersonalHTMLSummary'),
    t.literal('tikTokPersonalSearch'),
    t.literal('tikTokSearches'),
], 'TabouleQueryKey');
