import { Box, IconButton, Tooltip } from '@material-ui/core';
import CompareIcon from '@material-ui/icons/CompareOutlined';
import RelatedIcon from '@material-ui/icons/Replay30Outlined';
import * as React from 'react';
import CSVDownloadButton from '../components/buttons/CSVDownloadButton';
import DeleteButton from '../components/buttons/DeleteButton';
export var personalMetadataActions = function (commands, params) {
    // eslint-disable-next-line react/display-name
    return function (cellParams) {
        return (React.createElement(Box, { position: 'relative' },
            React.createElement(DeleteButton, { id: cellParams.row.id, onClick: function () {
                    void commands.deleteContribution({
                        Params: {
                            publicKey: params.publicKey,
                            selector: 'undefined',
                        },
                    }, {
                        personalSearches: {
                            Params: params,
                        },
                    })();
                } }),
            React.createElement(Tooltip, { title: "Compare", placement: "top" },
                React.createElement(IconButton, { size: "small" },
                    React.createElement(CompareIcon, { color: "error" }))),
            React.createElement(Tooltip, { title: "Related", placement: "top" },
                React.createElement(IconButton, { size: "small" },
                    React.createElement(RelatedIcon, { color: "error" }))),
            React.createElement(CSVDownloadButton, { onClick: function () {
                    void commands.downloadSearchesAsCSV({
                        Params: {
                            queryString: cellParams.row.query,
                        },
                    }, {
                        personalSearch: {
                            Params: params,
                        },
                    })();
                } })));
    };
};
