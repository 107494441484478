"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.Handshake = void 0;
var t = __importStar(require("io-ts"));
var ts_endpoint_1 = require("ts-endpoint");
var ContributionEvent_1 = require("@shared/models/ContributionEvent");
var ContributorPublicKey_1 = require("@shared/models/contributor/ContributorPublicKey");
var Experiment_1 = require("@shared/models/Experiment");
var HandshakeBody_1 = require("@shared/models/HandshakeBody");
var PublicKey_1 = require("@shared/models/http/params/PublicKey");
var SearchQuery_1 = require("@shared/models/http/SearchQuery");
var ChannelADV_1 = require("@shared/models/stats/ChannelADV");
var endpoints_1 = require("@shared/endpoints");
var YTHeaders_1 = require("../../models/http/YTHeaders");
exports.Handshake = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v2/handshake"; },
    Input: {
        Body: HandshakeBody_1.HandshakeBody
    },
    Output: HandshakeBody_1.HandshakeResponse
});
var CompareVideo = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var videoId = _a.videoId;
        return "/v2/compare/".concat(videoId);
    },
    Input: {
        Params: t.type({ videoId: t.string })
    },
    Output: t.any
});
var VideoRelated = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var videoId = _a.videoId;
        return "/v2/related/".concat(videoId);
    },
    Input: {
        Params: t.type({ videoId: t.string })
    },
    Output: t.any
});
var VideoAuthor = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var videoId = _a.videoId;
        return "/v2/author/".concat(videoId);
    },
    Input: {
        Params: t.type({ videoId: t.string })
    },
    Output: t.any
});
var Searches = (0, endpoints_1.DocumentedEndpoint)({
    title: 'Search by type',
    description: 'Search description',
    tags: ['searches'],
    Method: 'GET',
    getPath: function (_a) {
        var queryString = _a.queryString;
        return "/v2/searches/".concat(queryString);
    },
    Input: {
        Params: t.type({ queryString: t.string })
    },
    Output: t.any
});
var SearchesAsCSV = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var queryString = _a.queryString;
        return "/v2/searches/".concat(queryString, "/csv");
    },
    Input: {
        Params: t.type({ queryString: t.string })
    },
    Output: t.any
});
var GetPersonalCSV = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var publicKey = _a.publicKey, type = _a.type;
        return "/v2/personal/".concat(publicKey, "/").concat(type, "/csv");
    },
    Input: {
        Params: t.type(__assign(__assign({}, PublicKey_1.PublicKeyParams.props), { type: t.union([
                t.literal('home'),
                t.literal('video'),
                t.literal('search'),
            ]) }))
    },
    Output: t.any
});
var AddEvents = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v2/events"; },
    Input: {
        Headers: YTHeaders_1.YTHeaders,
        Body: ContributionEvent_1.AddEventsBody
    },
    Output: t.any
});
var AddAPIEvents = (0, ts_endpoint_1.Endpoint)({
    Method: 'POST',
    getPath: function () { return "/v2/apiEvents"; },
    Input: {
        Headers: YTHeaders_1.YTHeaders,
        Body: ContributionEvent_1.AddEventsBody
    },
    Output: t.any
});
var GetChannelADVStats = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var channelId = _a.channelId;
        return "/v2/ad/channel/".concat(channelId);
    },
    Input: {
        Query: t.type({
            since: t.string,
            till: t.string
        }),
        Params: t.type({
            channelId: t.string
        })
    },
    Output: t.array(ChannelADV_1.ChannelADVStats)
});
var GetExperimentList = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function () { return "/v2/guardoni/list"; },
    Input: {
        Query: SearchQuery_1.SearchQuery
    },
    Output: Experiment_1.GetExperimentListOutput
});
var GetExperimentById = (0, ts_endpoint_1.Endpoint)({
    Method: 'GET',
    getPath: function (_a) {
        var experimentId = _a.experimentId;
        return "/v2/experiment/".concat(experimentId, "/json");
    },
    Input: {
        Query: SearchQuery_1.SearchQuery,
        Params: t.type({
            experimentId: t.string
        })
    },
    Output: Experiment_1.GuardoniExperiment
});
var DeletePersonalContributionByPublicKey = (0, ts_endpoint_1.Endpoint)({
    Method: 'DELETE',
    getPath: function (_a) {
        var publicKey = _a.publicKey, selector = _a.selector;
        return "/v2/personal/".concat(publicKey, "/selector/id/").concat(selector);
    },
    Input: {
        Params: t.type(__assign(__assign({}, PublicKey_1.PublicKeyParams.props), { selector: t.union([t.string, t.undefined]) }))
    },
    Output: ContributorPublicKey_1.ContributorPublicKeyResponse
});
var Public = {
    Handshake: exports.Handshake,
    CompareVideo: CompareVideo,
    VideoRelated: VideoRelated,
    VideoAuthor: VideoAuthor,
    Searches: Searches,
    SearchesAsCSV: SearchesAsCSV,
    AddEvents: AddEvents,
    AddAPIEvents: AddAPIEvents,
    GetChannelADVStats: GetChannelADVStats,
    GetExperimentList: GetExperimentList,
    GetExperimentById: GetExperimentById,
    GetPersonalCSV: GetPersonalCSV,
    DeletePersonalContributionByPublicKey: DeletePersonalContributionByPublicKey
};
exports["default"] = Public;
