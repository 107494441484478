var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
var SettingsIcon = function (_a) {
    var color = _a.color, props = __rest(_a, ["color"]);
    return (React.createElement("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17.41 6.59L15 5.5L17.41 4.41L18.5 2L19.59 4.41L22 5.5L19.59 6.59L18.5 9L17.41 6.59ZM21.28 12.72L20.5 11L19.72 12.72L18 13.5L19.72 14.28L20.5 16L21.28 14.28L23 13.5L21.28 12.72ZM16.24 14.37L18.18 15.84L15.68 20.17L13.44 19.23C13.24 19.36 13.02 19.49 12.8 19.6L12.5 22H7.50001L7.20001 19.59C6.98001 19.48 6.77001 19.36 6.56001 19.22L4.32001 20.16L1.82001 15.83L3.76001 14.36C3.75001 14.25 3.75001 14.12 3.75001 14C3.75001 13.88 3.75001 13.75 3.76001 13.63L1.82001 12.16L4.32001 7.83L6.56001 8.77C6.76001 8.64 6.98001 8.51 7.20001 8.4L7.50001 6H12.5L12.8 8.41C13.02 8.52 13.23 8.64 13.44 8.78L15.68 7.84L18.18 12.17L16.24 13.64C16.25 13.76 16.25 13.88 16.25 14.01C16.25 14.14 16.25 14.25 16.24 14.37ZM13 14C13 12.34 11.66 11 10 11C8.34001 11 7.00001 12.34 7.00001 14C7.00001 15.66 8.34001 17 10 17C11.66 17 13 15.66 13 14Z", fill: color })));
};
export default SettingsIcon;
